import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Item = ({ active, item, hideIcon }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClick = (e) => {
    e.preventDefault();
    navigate(item.menu_route);
  };

  // styling
  let background = colors.menu["background"];
  let fontWeight = 400;
  let color = colors.menu["text"];

  if (active) {
    background = colors.menu.active.background;
    color = colors.menu.active.text;
    fontWeight = 500;
  }

  return (
    <ListItem disablePadding sx={{ display: "block", my: "2.5px" }}>
      <ListItemButton
        sx={{
          justifyContent: "initial",
          px: 1.5,
          borderRadius: 1,
          background,
          "& .MuiListItemText-root": {
            margin: 0,
          },
        }}
        onClick={handleClick}
      >
        {!hideIcon && (
          <ListItemIcon
            color={color}
            sx={{
              minWidth: 0,
              mr: "auto",
              "& .MuiSvgIcon-root": {
                color,
              },
            }}
          >
            {item.iconType === "fontawesome" && item.icon ? (
              <FontAwesomeIcon icon={item.icon} />
            ) : (
              item.icon
            )}
          </ListItemIcon>
        )}
        <ListItemText
          primary={item.menu_item}
          sx={{
            "& .MuiTypography-root": {
              color,
              fontWeight,
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default Item;
