import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  CircularProgress,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import TMSTreatmentPlanCard from "./TMSTreatmentPlanCard";
import TMSTreatmentPlanForm from "./TMSTreatmentPlanForm";
import logger from "../../../utils/logger";
import { getData, postData, putData } from "../../../utils/API";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { twoAttributeSort } from "../../../utils/sort";
import EpisodeTabNavigation from "../EpisodeTabNavigation";
import {
  validateRequiredAttributes,
  validateUniqueProperties,
} from "../../../utils/ValidationUtils";

const EpisodeTMSTreatmentPlans = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const colors = tokens(theme.palette.mode);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const location = useLocation();
  const { episodeOfCare } = location.state;
  const patientName = `${episodeOfCare["first_name"]} ${episodeOfCare["last_name"]}`;
  const practiceId = episodeOfCare["practice_id"];
  const officeId = episodeOfCare["office_id"];
  const officeName = episodeOfCare["office_name"];
  const patientId = episodeOfCare["patient_id"];
  const episodeOfCareId = episodeOfCare["id"];
  const practitionerId = episodeOfCare["practitioner_id"];
  const episodeStartDate = episodeOfCare["start_date"];
  const episodeEndDate = episodeOfCare["end_date"];
  const table = "episode_tms_treatment_plans";
  const sort_1 = "tms_protocol_name";
  const sort_2 = "session_start";
  const [treatmentPlan, setTreatmentPlan] = useState([]);
  const [practiceTreatmentPlans, setPracticeTreatmentPlans] = useState([]);
  const [episodeTreatmentSessions, setEpisodeTreatmentSessions] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [treatmentPlanData, setTreatmentPlanData] = useState();
  const [episodeTmsTreatMentPlanId, setEpisodeTmsTreatMentPlanId] = useState();
  const [openAddTreatmentPlanDialog, setOpenAddTreatmentPlanDialog] =
    useState(false);
  const [showSessionCard, setShowSessionCard] = useState([]);
  const [practitionerObjects, setPractitionerObjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const query_params = {
    practice_id: practiceId,
    episode_of_care_id: episodeOfCareId,
    deleted: false,
  };

  const getPlanData = async () => {
    try {
      let data = await getData(table, query_params);
      data = twoAttributeSort(data, sort_1, sort_2, false, false); // sort items
      setTreatmentPlan(data);

      // logger
      const child = logger.child(data);
      child.info("Treatment plans");
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  const validateRow = async (oldRows, newRow) => {
    const requiredAttributes = [
      "episode_of_care_id",
      "practice_tms_treatment_plan_id",
      "session_end",
      "session_start",
    ];
    const attributeNames = [
      "Episode of care id",
      "Practice of treatment plan id",
      "Session end",
      "Session start",
    ];

    try {
      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      const sameProtocolPlans = oldRows
        .filter(
          (row) =>
            row.tms_protocol_name === newRow.tms_protocol_name &&
            row.id !== newRow.id
        )
        .sort((a, b) => a.session_start - b.session_start);

      // New validation for overlapping sessions
      for (const plan of sameProtocolPlans) {
        if (Number(newRow.session_start) <= Number(plan.session_end)) {
          const overlapError = new Error();
          overlapError.name = "Data Validation Error";
          overlapError.message = `${newRow.tms_protocol_name} has an overlapping Treatment Plan with session ${plan.session_start} - ${plan.session_end}`;
          throw overlapError;
        }
      }
    } catch (error) {
      handleErrorNotification(error);
      return true;
    }
    return false;
  };

  // Function to save treatment plan
  const saveTreatmentPlan = async (values) => {
    const isValidate = await validateRow(treatmentPlan, values);
    if (isValidate) {
      return;
    }

    if (isUpdate) {
      const count =
        Number(values.session_end) - Number(values.session_start) + 1;
      const sessionExists = episodeTreatmentSessions.filter(
        (session) => session.episode_tms_treatment_plan_id === values.id
      ).length;

      if (sessionExists > count) {
        // If the number of existing sessions is less than or equal to the total sessions allowed by the treatment plan
        // Allowed sessions are calculated based on treatment plan session end and session start.
        setIsUpdate(false);
        const customError = new Error();
        customError.name = "Validation Error";
        customError.message = `${sessionExists} treatment sessions exist and the treatment plan only covers ${count} sessions.  
          \nDelete treatment sessions or increase the session count in the treatment plan.`;
        handleErrorNotification(customError);
        return;
      }
      try {
        await putData(table, values);
        getPlanData();
      } catch (error) {
        handleErrorNotification(error);
      }
    } else {
      try {
        await postData(table, values);
        getPlanData();
      } catch (error) {
        handleErrorNotification(error);
      }
    }
    setIsUpdate(false);
  };

  const handleAddOrUpdateTreatmentPlan = (values) => {
    if (isUpdate) {
      values.id = episodeTmsTreatMentPlanId;
    }

    setOpenAddTreatmentPlanDialog(false);
    saveTreatmentPlan(values);

    if (isUpdate) {
      setIsUpdate(false);
    }
  };

  const openEditTreatmentPlanModal = async (values) => {
    logger.info(`handleEditTreatmentPlan ${values}`);
    setEpisodeTmsTreatMentPlanId(values.id);
    setIsUpdate(true);
    setTreatmentPlanData(values);
    setOpenAddTreatmentPlanDialog(true);
  };

  async function treatmentSessionExists(id) {
    try {
      const result = await getData("episode_tms_treatment_sessions", {
        episode_tms_treatment_plan_id: id,
        deleted: false,
        episode_of_care_id: episodeOfCareId,
      });

      if (result.length === 0) {
        return false;
      } else {
        return result.length;
      }
    } catch (error) {
      return false;
    }
  }

  const handleDeleteTreatmentPlan = async (id) => {
    logger.info(`handleDeleteTreatmentPlan ${id}`);

    const treatmentsExists = await treatmentSessionExists(id);
    if (treatmentsExists) {
      const customError = new Error();
      customError.name = "Delete Error";
      customError.message = `This Treatment Plan has Treatment Sessions and cannot be deleted.\nDelete the Treatment Sessions or Add a new Treatment Plan and move the Treatment Sessions to the new Treatment Plan and then do the delete.`;
      handleErrorNotification(customError);
      return;
    }
    try {
      await putData(table, { id: id, deleted: true });
    } catch (error) {
      handleErrorNotification(error);
    }

    setTreatmentPlan(() =>
      treatmentPlan.filter((treatmentPlan) => treatmentPlan.id !== id)
    );
  };

  const handleCardClick = (index) => {
    // Check if the clicked treatment row index is already in the showSessionCard array
    if (showSessionCard.includes(index)) {
      // If it is, remove the index from the array to hide the session card
      setShowSessionCard(showSessionCard.filter((item) => item !== index));
    } else {
      // If it is not, add the index to the array to show the session card
      setShowSessionCard((prev) => [...prev, index]);
    }
  };

  const getTreatmentSessions = async () => {
    const table = "episode_tms_treatment_sessions";

    try {
      let data = await getData(table, query_params);
      data = data.sort((a, b) => a.session_date.localeCompare(b.session_date));
      setEpisodeTreatmentSessions(data || []);

      // logger
      const child = logger.child(data);
      child.info("Treatment sessions");
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  useEffect(() => {
    const queryParams = {
      practice_id: practiceId,
      office_id: officeId,
      deleted: false,
    };
    getData("practice_tms_treatment_plans", queryParams)
      .then((data) => {
        setPracticeTreatmentPlans(data);
        if (data.length <= 0) {
          const customError = new Error();
          customError.name = "TMS Treatment Plan Error";
          customError.message = `No treatment plans exist for ${officeName}.  Contact your System Administrator to setup TMS Treatment Plans`;
          throw customError;
        }
      })
      .catch((error) => {
        handleErrorNotification(error);
      });
  }, [practiceId, officeId]);

  const getPractitioners = async () => {
    try {
      const data = await getData("practice_practitioners", {
        deleted: false,
        status: "Active",
      });
      setPractitionerObjects(
        data.filter(
          (item) => item.title === "Doctor" || item.title === "TMS Technician"
        )
      );
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  const initializeConnections = async () => {
    await getPractitioners();
    await getPlanData();
    await getTreatmentSessions();
    setLoading(false);
  };

  useEffect(() => {
    initializeConnections();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <Box>
      {/* HEADER */}
      <EpisodeTabNavigation />
      <Box mt={3} display="flex" flexDirection="column" alignItems="flex-start">
        <Header title={patientName} />
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" width="100%">
            {/* Diagnosis cards on the left */}
            <Box
              width="100%"
              mr={2}
              sx={{
                padding: 2,
                borderRadius: "5px",
                background: isDark ? colors.primary[600] : colors.primary[600],
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h5"
                  fontWeight="800"
                  align="left"
                  component="div"
                  gutterBottom
                  color={colors.grey[10]}
                >
                  TREATMENT PLANS
                </Typography>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setOpenAddTreatmentPlanDialog(true);
                    setTreatmentPlanData({});
                    setIsUpdate(false);
                  }}
                >
                  <AddCircleOutlineIcon
                    sx={{
                      color: theme.palette.mode === "dark" ? "#fff" : "#000",
                    }}
                  />
                </IconButton>
              </Box>

              <Stack gap={4}>
                {treatmentPlan.map((treatment_plan, index) => (
                  <TMSTreatmentPlanCard
                    treatmentPlan={treatment_plan}
                    showSessions={showSessionCard.includes(index)}
                    key={treatment_plan.id}
                    treatmentSessions={episodeTreatmentSessions.filter(
                      (session) =>
                        session.episode_tms_treatment_plan_id ===
                        treatment_plan.id
                    )}
                    practiceId={practiceId}
                    practitionerId={practitionerId}
                    officeId={officeId}
                    patientId={patientId}
                    episodeOfCareId={episodeOfCareId}
                    episodeStartDate={episodeStartDate}
                    episodeEndDate={episodeEndDate}
                    practitionerObjects={practitionerObjects}
                    onEdit={() => openEditTreatmentPlanModal(treatment_plan)}
                    onDelete={() =>
                      handleDeleteTreatmentPlan(treatment_plan.id)
                    }
                    onClick={() => handleCardClick(index)}
                    onSessionSubmitted={(sessions) => {
                      setEpisodeTreatmentSessions([
                        ...episodeTreatmentSessions.filter(
                          (session) =>
                            session.episode_tms_treatment_plan_id !==
                            treatment_plan.id
                        ),
                        ...sessions,
                      ]);
                    }}
                  />
                ))}
              </Stack>
            </Box>
          </Box>
          <Dialog
            open={openAddTreatmentPlanDialog}
            onClose={() => setOpenAddTreatmentPlanDialog(false)}
          >
            <DialogTitle>
              {isUpdate ? "Edit Treatment Plan" : "Add Treatment Plan"}
            </DialogTitle>
            <DialogContent>
              <TMSTreatmentPlanForm
                onSubmit={(values) => handleAddOrUpdateTreatmentPlan(values)}
                onCancel={() => setOpenAddTreatmentPlanDialog(false)}
                practiceId={practiceId}
                officeId={officeId}
                episodeOfCareId={episodeOfCareId}
                patientId={patientId}
                practiceTreatmentPlans={practiceTreatmentPlans}
                treatmentPlanData={treatmentPlanData}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default EpisodeTMSTreatmentPlans;
