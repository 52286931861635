import * as React from "react";
import "../../../index.css";
import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData } from "../../../utils/API";
import { typeOfDate } from "../../../utils/ValidationUtils";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import EpisodeTabNavigation from "../EpisodeTabNavigation";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import TMSDiagnosisForm from "./TMSDiagnosisForm";
import DataEntryForm from "../../../components/datagrid/dataEntryForm";
import useSecurity from "../../../hooks/use-security";

export default function EpisodeDiagnosis() {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const location = useLocation();

  const { episodeOfCare } = location.state;
  const episodeOfCareId = episodeOfCare["id"];
  const patientId = episodeOfCare["patient_id"];
  const orders = [1, 2, 3, 4, 5];

  const title = "Episode Diagnosis";
  const table = "episode_diagnosis";
  const subtitle = episodeOfCare.full_name;
  const { practiceId, menuItems, securityGroupMenus } = useContext(UserContext);
  const menuItem = menuItems.find(
    (menuItem) => menuItem.menu_component === "PracticeEpisodesDataGrid"
  );
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname: menuItem.menu_route,
    securityGroupMenus,
  });

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const query_params = {
    episode_of_care_id: episodeOfCareId,
  };
  const [codeObjects, setCodeObjects] = useState([]);
  const [codeNames, setCodeNames] = useState([]);
  const [codeRange, setCodeRange] = useState([]);
  const [selectedDiagnosisData, setSelectedDiagnosisData] = useState({});
  const isUpdate = Boolean(selectedDiagnosisData);

  const columns = [
    {
      field: "dx_order",
      headerName: "Order",
      type: "singleSelect",
      valueOptions: [1, 2, 3, 4, 5],
      editable: true,
      flex: 1.0,
      defaultValue: "",
    },
    {
      field: "diagnosis_code",
      headerName: "ICD 10 Code",
      type: "singleSelect",
      valueOptions: ["First", "Second", "Third", "Fourth"],
      editable: true,
      flex: 1.5,
      defaultValue: "",
    },
    {
      field: "diagnosis_category",
      headerName: "Category",
      editable: true,
      flex: 3,
      defaultValue: 0,
    },
    {
      field: "diagnosis_long_description",
      headerName: "Description",
      flex: 5,
      defaultValue: "",
    },
    {
      field: "start_date",
      headerName: "Start",
      type: "date",
      flex: 1.5,
      editable: true,
      valueGetter: (params) => {
        const scoreDate = params.row.start_date;
        return typeOfDate(scoreDate, "object");
      },
    },
    {
      field: "end_date",
      headerName: "End",
      type: "date",
      flex: 1.5,
      editable: true,
      valueGetter: (params) => {
        const scoreDate = params.row.end_date;
        return typeOfDate(scoreDate, "object");
      },
    },
  ];

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  const saveDiagnosis = async (newRow) => {
    if (isUpdate) {
      newRow.id = selectedDiagnosisData.id;
      putData(table, newRow)
        .then((data) => {
          getDiagnosisData();
        })
        .catch((error) => {
          handleErrorNotification(error);
        });
    } else {
      postData(table, newRow)
        .then((data) => {
          getDiagnosisData();
        })
        .catch((error) => {
          handleErrorNotification(error);
        });
    }

    setIsOpenModal(false);
  };

  const openModal = (id) => {
    if (
      !codeNames ||
      codeNames.length === 0 ||
      !codeObjects ||
      codeObjects.length === 0
    ) {
      const customError = new Error();
      customError.name = "Data Error";
      customError.message = `Data could not be retrieved at this time. Please check your connection and try again, or contact support if the issue persists.`;
      handleErrorNotification(customError);
      return; // Prevent opening the modal
    }

    let selectedRow = {};
    setIsOpenModal(true);

    if (id) {
      //update
      selectedRow = rows.find((r) => r.id === id);
      const codeRange = codeObjects.find(
        (code) => code.id === selectedRow.diagnosis_code_id
      );
      selectedRow.codeRange = codeRange.icd10_code_range;
      setSelectedDiagnosisData(selectedRow);
    } else {
      setSelectedDiagnosisData(null);
    }
  };

  const getDiagnosisData = () => {
    setLoading(true);
    getData(table, query_params)
      .then((data) => {
        setRows(data.sort((a, b) => a.dx_order - b.dx_order));
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function deleteRow(id, row, oldRows) {
    const rowToSave = { ...row, deleted: true };

    try {
      await putData(table, rowToSave);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    getData("diagnosis_codes")
      .then((data) => {
        // Filter out rows where status is not Active
        const activeData = data.filter((row) => row.status === "Active");
        setCodeObjects(activeData);
        //const codes = activeData.map((obj) => obj.name);
        setCodeNames(activeData.map((obj) => obj.code));
        const codeRangeArray = activeData.map((obj) => obj.icd10_code_range);
        setCodeRange(
          codeRangeArray.filter(
            (item, index) => codeRangeArray.indexOf(item) === index
          )
        );
      })
      .catch((error) => {
        handleErrorNotification(error);
      });
  }, [handleErrorNotification]);

  useEffect(() => {
    setLoading(true);
    getData(table, query_params)
      .then((data) => {
        setRows(data.sort((a, b) => a.dx_order - b.dx_order));
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [episodeOfCareId, handleErrorNotification]);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  const allowedOrders = () => {
    const existOrders = [];
    for (const row of rows) {
      existOrders.push(row.dx_order);
    }

    return [...new Set(orders)].filter((order) => !existOrders.includes(order));
  };

  return (
    <div>
      <EpisodeTabNavigation />

      <DataEntryForm
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        filterKey="EpisodeDiagnosis"
        onDeleteRow={deleteRow}
        onAdd={openModal}
        onEdit={openModal}
        loading={loading}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />

      <Dialog open={isOpenModal} onClose={() => setIsOpenModal(false)}>
        <DialogTitle>
          {isUpdate ? "Edit Diagnosis" : "Add Diagnosis"}
        </DialogTitle>

        <DialogContent>
          <TMSDiagnosisForm
            onSubmit={(values) => saveDiagnosis(values)}
            onCancel={() => setIsOpenModal(false)}
            practiceId={practiceId}
            episodeOfCareId={episodeOfCareId}
            patientId={patientId}
            codeNames={codeNames}
            codeObjects={codeObjects}
            selectedDiagnosisData={selectedDiagnosisData}
            orders={allowedOrders()}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
