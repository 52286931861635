import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Menu,
} from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MenuItem = ({ prefix, icon, iconType, hideIcon, active, children }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleItemClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // styling
  let background = colors.menu["background"];
  let fontWeight = 400;
  let color = colors.menu["text"];

  if (menuOpen) {
    background = colors.menu.open.background;
    color = colors.menu.open.text;
    fontWeight = 500;
  }

  if (active) {
    background = colors.menu.active.background;
    color = colors.menu.active.text;
    fontWeight = 700;
  }

  const handleClick = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <div>
      <ListItem
        disablePadding
        sx={{ display: "block", my: "2.5px" }}
        onClick={handleItemClick}
      >
        <ListItemButton
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            px: 1,
            borderRadius: 1,
            background: background,
            minHeight: "auto",
            "& .MuiTypography-root": {
              fontWeight,
              color,
            },
          }}
          onClick={handleClick}
        >
          {!hideIcon && (
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: "center",
                "& svg": {
                  width: "1.2rem",
                  height: "1.2rem",
                },
              }}
            >
              {iconType === "fontawesome" && icon ? (
                <FontAwesomeIcon color={color} icon={icon} />
              ) : (
                icon
              )}
            </ListItemIcon>
          )}

          <ListItemText
            primary={prefix}
            primaryTypographyProps={{ variant: "body1" }}
          />
        </ListItemButton>
      </ListItem>

      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiList-root": {
            p: 0,
            width: "200px",
          },
          "& .MuiPaper-root": {
            background: colors.menu.menu.background,
            marginTop: "20px",
          },
        }}
      >
        {children}
      </Menu>
    </div>
  );
};

export default MenuItem;
