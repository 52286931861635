import * as React from "react";
import { useEffect, useState, useContext } from "react";
import DataEntry from "../../../components/datagrid/dataEntry";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData, deleteData } from "../../../utils/API";
import { validateRequiredAttributes } from "../../../utils/ValidationUtils";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { useLocation } from "react-router-dom";
import useSecurity from "../../../hooks/use-security";

// *************** CUSTOMIZE ************** START
export default function TMSCoilsGrid() {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const title = "TMS Coils";
  let subtitle = `View ${title}`;

  const { pathname } = useLocation();
  const { menuItems, securityGroupMenus } = useContext(UserContext);
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });

  const table = "tms_coils";
  const sort_1 = "tms_device_manufacturer";
  const sort_2 = "tms_device_model_number";
  const sort_3 = "model_number";

  const requiredAttributes = [
    "tms_device_manufacturer",
    "tms_device_model_number",
    "model_number",
    "name",
    "status",
  ];
  const attributeNames = [
    "Device Mfg",
    "Device Model",
    "Coil Model",
    "Coil Name",
    "Status",
  ];

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "tms_device_manufacturer",
      headerName: "Device Mfg",
      editable: true,
      width: 200,
    },
    {
      field: "tms_device_model_number",
      headerName: "Device Model",
      editable: true,
      width: 200,
    },
    {
      field: "model_number",
      headerName: "Coil Model",
      editable: true,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Coil Name",
      editable: true,
      cellClassName: "name-column--cell",
      width: 200,
    },
    {
      field: "year",
      headerName: "Year",
      editable: true,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      align: "left",
      editable: true,
      cellClassName: "wrapWord",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: ["Active", "Inactive"],
      defaultValueGetter: () => "Active",
      width: 100,
    },
  ];

  const createRowData = (rows) => {
    // IS THIS REDUNDANT, ITS ALSO IN DefaultToolBar
    const newId = Math.floor(100000 + Math.random() * 900000);
    return {
      id: newId,
      tms_device_manufacturer: "",
      tms_device_model_number: "",
      model_number: "",
      name: "",
      year: null,
      description: "",
      status: "Active",
    };
  };
  // *************** CUSTOMIZE ************** END

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  useEffect(() => {
    setLoading(true);
    getData(table)
      .then((data) => {
        const sortedItems = sortItems(data, sort_1, sort_2, sort_3);
        setRows(sortedItems);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleErrorNotification]);

  function sortItems(
    items,
    sort_attribute_1,
    sort_attribute_2,
    sort_attribute_3
  ) {
    return items.sort((a, b) => {
      // Primary criterion: sort_attribute_1
      const comparison_1 = a[sort_attribute_1].localeCompare(
        b[sort_attribute_1]
      );

      // If the primary criteria are the same, check sort_attribute_2
      if (comparison_1 === 0) {
        const comparison_2 = a[sort_attribute_2].localeCompare(
          b[sort_attribute_2]
        );

        // If both primary and secondary criteria are the same, check sort_attribute_3
        if (comparison_2 === 0) {
          return a[sort_attribute_3].localeCompare(b[sort_attribute_3]); // Tertiary criterion
        }

        return comparison_2;
      }

      return comparison_1;
    });
  }

  async function validateRow(newRow, oldRow) {
    try {
      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      return newRow;
    } catch (error) {
      throw error;
    }
  }

  async function saveRow(id, row, oldRow, oldRows) {
    try {
      if (
        row.model_number === "" ||
        row.model_number === undefined ||
        row.model_number === null
      ) {
        row.model_number = row.name;
      }
      if (row.isNew) {
        const rowToSave = { ...row };
        // Delete the id that was generated when row was created
        delete rowToSave.id;
        rowToSave["active"] = "Active";
        const data = await postData(table, rowToSave);
        // Add the id returned from the database
        rowToSave.id = data.data.id;
        setRows(oldRows.map((r) => (r.id === id ? { ...rowToSave } : r)));
        return rowToSave;
      } else {
        await putData(table, row);
        setRows(oldRows.map((r) => (r.id === id ? { ...row } : r)));
        return row;
      }
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  async function deleteRow(id, row, oldRows) {
    const body = {
      id: row.id,
    };

    try {
      await deleteData(table, body);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntry
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        uniqueKey="TMSCoils"
        onValidateRow={validateRow}
        onSaveRow={saveRow}
        onDeleteRow={deleteRow}
        createRowData={createRowData}
        loading={loading}
        hideAddIcon={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />
    </div>
  );
}
