import React from "react";
import TabStructure from "../../components/tabStructure/TabStructure";

const tabs = [
  { label: "Year", value: "year" },
  { label: "Quarter", value: "year_quarter" },
  { label: "Office", value: "office_name" },
  { label: "Practitioner", value: "practitioner_full_name" },
];

// Define fieldOptions for each tab
const fieldOptionsByTab = {
  year: [
    { name: "follow_up_category", label: "Follow up Category", options: [] },
    { name: "office_name", label: "Office", options: [] },
    { name: "practitioner_full_name", label: "Practitioner", options: [] },
  ],
  year_quarter: [
    { name: "follow_up_category", label: "Follow up Category", options: [] },
    { name: "office_name", label: "Office", options: [] },
    { name: "practitioner_full_name", label: "Practitioner", options: [] },
    { name: "year", label: "Year", options: [] },
  ],
  office_name: [
    { name: "year", label: "Year", options: [] },
    { name: "year_quarter", label: "Quarter", options: [] },
    { name: "year_month", label: "Month", options: [] },
    { name: "follow_up_category", label: "Follow up Category", options: [] },
    { name: "practitioner_full_name", label: "Practitioner", options: [] },
  ],
  practitioner_full_name: [
    { name: "year", label: "Year", options: [] },
    { name: "year_quarter", label: "Quarter", options: [] },
    { name: "year_month", label: "Month", options: [] },
    { name: "follow_up_category", label: "Follow up Category", options: [] },
    { name: "office_name", label: "Office", options: [] },
  ],
};

const FollowUpsAnalysis = () => {
  const title = "New Patient Follow Ups (Business Days)";

  return (
    <TabStructure
      tableName="analysis_new_patient_follow_ups"
      tabs={tabs}
      countByKey="follow_up_category"
      title={title}
      displayDataLabels={false}
      fieldOptionsByTab={fieldOptionsByTab}
    />
  );
};

export default FollowUpsAnalysis;
