import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ColorModeContext, tokens } from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MenuItem from "./MenuItem";
import SubItem from "./SubItem";
import { useContext } from "react";
import UserContext from "../../contexts/UserContext";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppLayout({ children }) {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const { pathname } = useLocation();
  const { menus, menuItems } = useContext(UserContext);
  const updatedMenuItems = [...menuItems];

  const convertBackendMenuToTopMenu = (menus) => {
    const menuMap = {};

    menus.forEach((menu) => {
      menuMap[menu.name] = { ...menu, items: [] };
      const _menuItems = updatedMenuItems.filter(
        (item) => item.menu === menu.name
      );

      _menuItems.forEach((item) => {
        const section = item.menu_section;
        if (section) {
          // Find if the category exists
          let categoryGroup = menuMap[menu.name].items.find(
            (group) => group.section === section
          );

          if (!categoryGroup) {
            categoryGroup = {
              section,
              subItems: [],
            };
            menuMap[menu.name].items.push(categoryGroup);
          }

          categoryGroup.subItems.push(item);
        } else {
          menuMap[menu.name].items.push(item);
        }
      });
    });

    // Convert object to array structure required by the frontend
    return Object.values(menuMap);
  };

  // Convert the backend menu to the frontend structure
  const topMenu = convertBackendMenuToTopMenu(menus);

  async function signOut() {
    try {
      navigate("/");
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      throw error;
    }
  }

  const isMenuActive = (items) => {
    const _items = [];
    for (const item of items) {
      if (item.subItems) {
        _items.push(...item.subItems);
      } else {
        _items.push(item);
      }
    }
    const activeItem = _items.find((sub) => sub.menu_route === pathname);
    return Boolean(activeItem);
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <AppBar
        position="fixed"
        open
        sx={{
          boxShadow: "0px 0px 9px 0px rgb(0 0 0 / 20%)",
          zIndex: 98,
          backdropFilter: "blur(6px)",
          background: colors.topbar.background,
          width: "100%",
        }}
      >
        <Toolbar>
          <img alt="logo" src="/logo.png" style={{ width: "150px" }} />

          <List
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              justifyContent: "center",
              gap: 1,
              flex: 1,
            }}
            className={`sidebar-list`}
          >
            {topMenu.map((menu, index) => (
              <MenuItem
                key={`menu-item-${index}`}
                prefix={menu.name}
                icon={menu.icon}
                iconType={menu.iconType}
                active={isMenuActive(menu.items)}
              >
                {menu.items.map((item, subIndex) =>
                  item.section ? (
                    <div key={`sub-${subIndex}`}>
                      <Typography
                        variant="subtitle1"
                        whiteSpace={"pre"}
                        fontWeight={700}
                        sx={{
                          m: "15px 0 0 1rem",
                          textTransform: "uppercase",
                          textAlign: "left",
                        }}
                        color={colors.menu.text}
                      >
                        {item.section}
                      </Typography>
                      {item.subItems.map((subItem, subSubIndex) => (
                        <SubItem
                          hideIcon
                          active={pathname === subItem.menu_route}
                          key={`subitem-${subIndex}-${subSubIndex}`}
                          item={subItem}
                        />
                      ))}
                    </div>
                  ) : (
                    <SubItem
                      hideIcon
                      active={pathname === item.menu_route}
                      key={`subitem-${subIndex}`}
                      item={item}
                    />
                  )
                )}
              </MenuItem>
            ))}
          </List>

          <Box display="flex" justifyContent={`flex-end`}>
            <IconButton
              onClick={colorMode.toggleColorMode}
              sx={{ color: colors.menu.text }}
            >
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>

            <IconButton onClick={signOut} sx={{ color: colors.menu.text }}>
              <PowerSettingsNewIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, pt: "calc(64px + 24px)", overflowX: "hidden" }}
      >
        {children}
      </Box>
    </Box>
  );
}
