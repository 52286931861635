export const getColorByIndex = (index) => {
  const colors = [
    "rgba(255,99,132,0.8)",
    "rgba(54,162,235,0.8)",
    "rgba(75,192,192,0.8)",
    "rgba(255,206,86,0.8)",
    "rgba(153,102,255,0.8)",
    "rgba(255,159,64,0.8)",
    "rgba(255,99,71,0.8)",
    "rgba(30,144,255,0.8)",
    "rgba(218,165,32,0.8)",
    "rgba(100,149,237,0.8)",
    "rgba(186,85,211,0.8)",
    "rgba(240,128,128,0.8)",
    "rgba(0,128,128,0.8)",
    "rgba(255,215,0,0.8)",
    "rgba(0,255,127,0.8)",
    "rgba(70,130,180,0.8)",
    "rgba(123,104,238,0.8)",
    "rgba(255,140,0,0.8)",
    "rgba(255,105,180,0.8)",
    "rgba(144,238,144,0.8)",
    "rgba(106,90,205,0.8)",
    "rgba(199,21,133,0.8)",
    "rgba(255,69,0,0.8)",
    "rgba(32,178,170,0.8)",
    "rgba(250,128,114,0.8)",
    "rgba(0,191,255,0.8)",
    "rgba(220,20,60,0.8)",
    "rgba(34,139,34,0.8)",
    "rgba(0,0,205,0.8)",
    "rgba(255,20,147,0.8)",
  ];
  return colors[index % colors.length];
};
