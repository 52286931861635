import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Box,
  Collapse,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ExpandMoreRounded, ExpandLessRounded } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import logger from '../../../utils/logger';
import { postData, putData } from '../../../utils/API';
import { useNotificationHandling } from '../../../utils/NotificationHandling';
import DataEntry from '../../../components/datagrid/dataEntry';
import {
  typeOfDate,
  validateDateDefined,
  validateRequiredAttributes,
} from '../../../utils/ValidationUtils';
import dayjs from 'dayjs';
import { tokens } from '../../../theme';
import { getNextWeekday } from '../../../utils/DateUtils';

const table = 'episode_tms_treatment_sessions';

const TMSTreatmentPlanCard = ({
  treatmentPlan,
  onEdit,
  onDelete,
  onClick,
  onSessionSubmitted,
  showSessions,
  practiceId,
  officeId,
  patientId,
  episodeOfCareId,
  practitionerId,
  treatmentSessions,
  episodeStartDate,
  episodeEndDate,
  practitionerObjects,
}) => {
  const theme = useTheme();
  const { handleErrorNotification } = useNotificationHandling();
  const [sessions, setSessions] = useState(treatmentSessions || []);
  const motorThresholdCalibratedValue =
    treatmentPlan.motor_threshold_calibrated;
  const motorThresholdPercent = treatmentPlan.motor_threshold_percent;
  const motor_threshold_goal = Math.floor(
    motorThresholdCalibratedValue * (motorThresholdPercent / 100)
  );
  const columns = [
    { field: 'session_number', headerName: 'Session number', flex: 0.5 },
    {
      field: 'practitioner_name',
      headerName: 'Practitioner Name',
      editable: true,
      type: 'singleSelect',
      valueOptions: practitionerObjects.map((obj) => obj.full_name),
      width: 200,
    },
    {
      field: 'session_date',
      headerName: 'Session Date',
      headerAlign: 'left',
      align: 'left',
      editable: true,
      type: 'date',
      flex: 1,
      valueGetter: (params) => {
        const date = params.row.session_date;
        const dateObject = typeOfDate(date, 'object');
        return dateObject;
      },
    },
    {
      field: 'motor_threshold_initial',
      headerName: 'Initial Amplitude',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      editable: true,
      flex: 1,
    },
    {
      field: 'motor_threshold_final',
      headerName: 'Final Amplitude',
      headerAlign: 'left',
      align: 'left',
      editable: true,
      type: 'number',
      flex: 1,
    },
    {
      field: 'total_pulses',
      headerName: 'Total Pulses',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      type: 'number',
      flex: 1,
    },
  ];

  // Function to handle the deletion of a treatment session
  const handleDeleteTreatmentSession = async (id) => {
    logger.info(`handleDeleteTreatmentSession ${id}`);
    try {
      await putData(table, { id: id, deleted: true });
    } catch (error) {
      handleErrorNotification(error);
    }

    const _sessions = sessions.filter((session) => session.id !== id);
    setSessions(_sessions);
    onSessionSubmitted(_sessions);
  };

  // Function to delete a row
  const deleteRow = (id) => {
    handleDeleteTreatmentSession(id);
  };

  // Function to create a new row data
  const createRowData = (rows) => {
    const newId = Math.floor(100000 + Math.random() * 900000); // Generate a new unique ID for the row

    // Find session based on the latest session_date if sessions exist
    const maxSession =
      sessions && sessions.length > 0
        ? sessions.reduce((max, item) =>
            dayjs(item.session_date).valueOf() >
            dayjs(max.session_date).valueOf()
              ? item
              : max
          )
        : null;

    // Determine the next session date based on the latest session or episode start date
    const nextSessionDate = maxSession
      ? getNextWeekday(maxSession.session_date)
      : episodeStartDate;

    // Get the practitioner's name from the latest session or the practitioner by episode practitioner id
    const practitionerName = maxSession
      ? maxSession.practitioner_name
      : practitionerObjects.find((obj) => obj.id === practitionerId)
          ?.full_name || '';

    return {
      id: newId,
      practitioner_name: practitionerName,
      session_date: nextSessionDate,
      motor_threshold_initial: motor_threshold_goal,
      motor_threshold_final: motor_threshold_goal,
      total_pulses: treatmentPlan.total_pulses,
    };
  };

  // Function to validate a row
  const validateRow = async (newRow) => {
    const requiredAttributes = [
      'session_date',
      'motor_threshold_initial',
      'motor_threshold_final',
    ];
    const attributeNames = ['Session Date', 'MT Initial', 'MT Final'];
    const max = treatmentPlan.motor_threshold_calibrated * 2;
    const min = 1;
    const count =
      Number(treatmentPlan.session_end) -
      Number(treatmentPlan.session_start) +
      1;

    try {
      if (newRow.isNew && count <= sessions.length) {
        // If the new row is a new session and the number of existing sessions is less than or equal to the total sessions allowed by the treatment plan
        // Allowed sessions are calculated based on treatment plan session end and session start.
        const customError = new Error();
        customError.name = 'Validation Error';
        customError.message = `${count} treatment sessions exist and the treatment plan only covers ${sessions.length} session.  
        \nDelete treatment sessions or increase the session count in the treatment plan.`;
        throw customError;
      }

      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      validateDateDefined(newRow['session_date'], 'Session Date');

      const duplicateObject = sessions
        .filter((session) => session.id !== newRow.id)
        .find(
          (session) =>
            dayjs(session.session_date).valueOf() ===
            dayjs(newRow.session_date).valueOf()
        );

      // If there is already a session with the same date, throw a validation error
      if (duplicateObject) {
        const customError = new Error();
        customError.name = 'Validation Error';
        customError.message = `${dayjs(newRow.session_date).format(
          'YYYY-MM-DD'
        )} session date can't be added. it was duplicated`;
        throw customError;
      }

      // If the initial or final motor threshold is greater than the maximum allowed value, throw a validation error
      if (
        newRow['motor_threshold_initial'] > max ||
        newRow['motor_threshold_final'] > max
      ) {
        const customError = new Error();
        customError.name = 'Validation Error';
        customError.message = `Motor Threshold must be at most ${max}`;
        throw customError;
      }

      // If the initial or final motor threshold is less than the minimum allowed value, throw a validation error
      if (
        newRow['motor_threshold_initial'] < min ||
        newRow['motor_threshold_final'] < min
      ) {
        const customError = new Error();
        customError.name = 'Validation Error';
        customError.message = `Motor Threshold must be at least ${min}`;
        throw customError;
      }

      // If the session date is before the episode start date, throw a validation error
      if (
        dayjs(newRow['session_date']).valueOf() <
        dayjs(episodeStartDate).valueOf()
      ) {
        const customError = new Error();
        customError.name = 'Validation Error';
        customError.message = `Session Date must be on or after episode start date ${episodeStartDate}`;
        throw customError;
      }

      // If the session date is after the episode end date, throw a validation error
      if (
        dayjs(newRow['session_date']).valueOf() >
        dayjs(episodeEndDate).valueOf()
      ) {
        const customError = new Error();
        customError.name = 'Validation Error';
        customError.message = `Session Date must be on or before episode end date ${episodeEndDate}`;
        throw customError;
      }
      return newRow;
    } catch (error) {
      throw error;
    }
  };

  // Function to get object ID based on a key
  function getObjectID(newRow, keyToCompare, objectsArray, matchingKey) {
    const object = objectsArray.find(
      (obj) => obj[matchingKey] === newRow[keyToCompare]
    );
    if (object) {
      return object.id;
    } else {
      const customError = new Error();
      customError.name = 'Data Validation Error';
      customError.message = `${keyToCompare} not found.`;
      throw customError;
    }
  }

  // Function to validate values before saving
  const validateSaveValues = async (oldRows, newRow) => {
    const requiredAttributes = [
      'episode_of_care_id',
      'practice_tms_treatment_plan_id',
      'session_end',
      'session_date',
    ];
    const attributeNames = [
      'Episode of care id',
      'Practice of treatment plan id',
      'Session date',
    ];

    try {
      const isValidate = validateRequiredAttributes(
        requiredAttributes,
        attributeNames,
        newRow
      );
      return isValidate;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    }
  };

  // Function to save a new row
  async function saveRow(id, row, oldRow, oldRows) {
    try {
      const motorThresholdFinal = row.motor_threshold_final;
      const motorThresholdPercentFinal = motorThresholdFinal
        ? Math.ceil(
            (motorThresholdFinal / treatmentPlan.motor_threshold_calibrated) *
              100
          )
        : null;
      const practitionerId = getObjectID(
        row,
        'practitioner_name',
        practitionerObjects,
        'full_name'
      );

      const rowToSave = {
        ...row,
        session_date: dayjs(row.session_date).format('YYYY-MM-DD'),
        practice_id: practiceId,
        practitioner_id: practitionerId,
        office_id: officeId,
        patient_id: patientId,
        episode_of_care_id: episodeOfCareId,
        episode_tms_treatment_plan_id: treatmentPlan.id,
        motor_threshold_calibrated: treatmentPlan.motor_threshold_calibrated,
        total_pulses: treatmentPlan.total_pulses,
        status: 'Active',
        deleted: false,
        tms_device_manufacturer: treatmentPlan.tms_device_manufacturer,
        tms_device_model_number: treatmentPlan.tms_device_model_number,
        tms_coil_model_number: treatmentPlan.tms_coil_model_number,
        tms_protocol_name: treatmentPlan.tms_protocol_name,
        motor_threshold_percent_final: motorThresholdPercentFinal,
      };

      const isValidate = await validateSaveValues(treatmentSessions, rowToSave);
      if (isValidate) {
        return;
      }

      if (row.isNew) {
        // Create a new treatment session
        delete rowToSave.id;
        delete rowToSave.isNew;
        const res = await postData(table, rowToSave);
        rowToSave.id = res.data.id;
        rowToSave.practitioner_name = practitionerObjects.find(
          (obj) => obj.id === rowToSave.practitioner_id
        ).full_name;
      } else {
        // Update a treatment session
        await putData(table, rowToSave);
      }
      const _sessions = oldRows
        .map((r) => (r.id === id ? { ...rowToSave } : r))
        .sort((a, b) => a.session_date.localeCompare(b.session_date));
      setSessions(_sessions);
      onSessionSubmitted(_sessions); // Notify parent component of updated sessions
      return rowToSave;
    } catch (error) {
      setSessions(oldRows);
      handleErrorNotification(error);
    }
  }

  const handleOnEdit = (event) => {
    event.stopPropagation();
    onEdit && onEdit();
  };

  const handleOnDelete = (event) => {
    event.stopPropagation();
    onDelete && onDelete();
  };

  // Render the component
  return (
    <Box>
      <Card
        sx={{
          width: '100%',
        }}
        onClick={onClick}
      >
        <CardContent sx={{ padding: '10px 4px' }}>
          <Grid container spacing={1}>
            {/* Row 1 */}
            <Grid item xs={1.5}>
              <Typography variant='subtitle1'>Device</Typography>
              <Typography variant='body1' color='text.secondary'>
                {treatmentPlan.tms_device_manufacturer}
              </Typography>
              <Typography variant='body1' color='text.secondary'>
                {treatmentPlan.tms_device_model}
              </Typography>
              <Typography variant='body1' color='text.secondary'>
                {treatmentPlan.tms_coil_model_number} coil
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction='column'
              alignItems='center'
            >
              <Typography variant='subtitle1'>Protocol</Typography>
              <Typography variant='body1' color='text.secondary'>
                {treatmentPlan.tms_protocol_name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction='column'
              alignItems='center'
            >
              <Typography variant='subtitle1' align='center'>
                MT Percent
              </Typography>
              <Typography variant='body1' color='text.secondary' align='center'>
                {treatmentPlan.motor_threshold_percent} %
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction='column'
              alignItems='center'
            >
              <Typography variant='subtitle1'>MT Calibrated</Typography>
              <Typography variant='body1' color='text.secondary'>
                {treatmentPlan.motor_threshold_calibrated}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction='column'
              alignItems='center'
            >
              <Typography variant='subtitle1'>Session Start</Typography>
              <Typography variant='body1' color='text.secondary'>
                {treatmentPlan.session_start}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction='column'
              alignItems='center'
            >
              <Typography variant='subtitle1'>Session End</Typography>
              <Typography variant='body1' color='text.secondary'>
                {treatmentPlan.session_end}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction='column'
              alignItems='center'
            >
              {treatmentPlan.status === 'Active' ? (
                <>
                  <CheckCircleOutlineIcon color='success' fontSize='large' />
                  <Typography
                    variant='body1'
                    color='text.secondary'
                    sx={{ mt: 1, textAlign: 'center' }}
                  >
                    ACTIVE
                  </Typography>

                  {sessions.length > 0 && (
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      sx={{ mt: 1 }}
                    >
                      {`${sessions.length} Treatment session${
                        sessions.length > 1 ? 's' : ''
                      }`}
                    </Typography>
                  )}
                </>
              ) : treatmentPlan.status === 'Inactive' ? (
                <>
                  <HighlightOffIcon color='error' fontSize='large' />
                  <Typography
                    variant='body1'
                    color='text.secondary'
                    sx={{ mt: 1 }}
                  >
                    INACTIVE
                  </Typography>

                  {sessions.length > 0 && (
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      sx={{ mt: 1 }}
                    >
                      {`${sessions.length} Treatment session${
                        sessions.length > 1 ? 's' : ''
                      }`}
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <Typography variant='subtitle1'>Status</Typography>
                  <Typography variant='body1' color='text.secondary'>
                    {treatmentPlan.status}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              justifyContent='flex-end'
              alignItems='center'
            >
              <IconButton onClick={handleOnEdit} aria-label='edit' size='large'>
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={handleOnDelete}
                aria-label='delete'
                size='large'
              >
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={onClick} aria-label='collapse'>
                {showSessions ? (
                  <ExpandLessRounded fontSize={'large'} />
                ) : (
                  <ExpandMoreRounded fontSize={'large'} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Collapse in={showSessions}>
        {/* Session card */}
        <Card
          sx={{
            width: '100%',
            mt: 3,
          }}
        >
          <Typography
            variant='h5'
            sx={{ p: '20px', pb: 0 }}
          >{`Treatment Sessions`}</Typography>
          <DataEntry
            containerStyle={{
              height: 'auto',
              m: 0,
            }}
            columns={columns}
            rows={sessions.map((session, i) => ({ ...session, no: i + 1 }))}
            uniqueKey='EpisodeTMSTreatmentPlans'
            onValidateRow={validateRow}
            onSaveRow={saveRow}
            onDeleteRow={deleteRow}
            createRowData={createRowData}
            loading={practitionerObjects.length <= 0}
          />
        </Card>
      </Collapse>
    </Box>
  );
};

export default TMSTreatmentPlanCard;
