import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Button,
  Grid,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
} from "@mui/material";

const PracticeUserForm = ({
  onCancel,
  onSubmit,
  selectedRow,
  groups,
  rows,
}) => {
  const validationSchema = Yup.object().shape({
    given_name: Yup.string().required("First Name is required"),
    family_name: Yup.string()
      .required("Last Name is required")
      .test("is-name-unique", function (value) {
        const { given_name } = this.parent;

        const isExist = rows
          .filter((row) => row.first_name !== given_name)
          .some((row) => row.last_name === value);

        if (isExist) {
          return this.createError({
            path: this.path,
            message: `${given_name} and ${value} already exists`,
          });
        }

        return true;
      }),
    email: Yup.string()
      .required("Email is required")
      .test("is-email-unique", function (value) {
        const isExist = rows
          .filter((row) => row.id !== selectedRow?.id)
          .some((row) => row.email === value);

        if (isExist) {
          return this.createError({
            path: this.path,
            message: `${value} already exists`,
          });
        }
        return true;
      }),
    phone_number: Yup.string()
      .required("Phone Number is required")
      .matches(
        /^\+\d{10,15}$/,
        "Phone number must be in the format +1234567890 and contain only digits"
      )
      .test("is-phone-unique", function (value) {
        const isExist = rows
          .filter((row) => row.id !== selectedRow?.id)
          .some((row) => row.phone_number === value);

        if (isExist) {
          return this.createError({
            path: this.path,
            message: `${value} already exists`,
          });
        }
        return true;
      }),
    groups: Yup.array()
      .min(1, "At least one group is required") // Ensure at least one group is selected
      .required("At least 1 security group is required"), // Marks the field as required
  });

  return (
    <Formik
      initialValues={{
        given_name: selectedRow?.first_name || "",
        family_name: selectedRow?.last_name || "",
        email: selectedRow?.email || "",
        phone_number: selectedRow?.phone_number || "",
        groups: selectedRow?.groups || [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, touched, handleChange, setFieldValue, values }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 600,
              mx: "auto",
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="given_name"
                  as={TextField}
                  label="First Name"
                  type="text"
                  fullWidth
                  value={values.given_name}
                  onChange={handleChange}
                  error={touched.given_name && Boolean(errors.given_name)}
                  helperText={touched.given_name && errors.given_name}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="family_name"
                  as={TextField}
                  label="Last Name"
                  type="text"
                  fullWidth
                  value={values.family_name}
                  onChange={handleChange}
                  error={touched.family_name && Boolean(errors.family_name)}
                  helperText={touched.family_name && errors.family_name}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="email"
                  as={TextField}
                  label="Email"
                  type="email"
                  fullWidth
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="phone_number"
                  as={TextField}
                  label="Phone Number"
                  type="text"
                  fullWidth
                  value={values.phone_number}
                  onChange={handleChange}
                  error={touched.phone_number && Boolean(errors.phone_number)}
                  helperText={touched.phone_number && errors.phone_number}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={touched.groups && Boolean(errors.groups)}
                >
                  <InputLabel>{`Groups`}</InputLabel>
                  <Select
                    multiple
                    label={`Security Groups`}
                    value={values.groups}
                    name="groups"
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;

                      if (value.includes("all")) {
                        setFieldValue(
                          "groups",
                          groups.map((group) => group.name)
                        );
                      } else if (value.includes("removeAll")) {
                        setFieldValue("groups", []);
                      } else {
                        setFieldValue("groups", value);
                      }
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return "";
                      } else if (
                        selected.length === groups.length ||
                        selected.includes("all")
                      ) {
                        return "All";
                      } else if (selected.length > 1) {
                        return "Multiple";
                      } else {
                        return selected[0];
                      }
                    }}
                  >
                    <MenuItem value="all">
                      <Checkbox
                        checked={values.groups.length === groups.length}
                      />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    <MenuItem value="removeAll">
                      <Checkbox checked={values.groups.length === 0} />
                      <ListItemText primary="Remove All" />
                    </MenuItem>
                    {groups.map((group, optionIndex) => (
                      <MenuItem key={optionIndex} value={group.name}>
                        <Checkbox
                          checked={values.groups.includes(group.name)}
                        />
                        <ListItemText primary={group.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.groups && errors.groups && (
                    <FormHelperText>{errors.groups}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PracticeUserForm;
