// RequireAuth.js

import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { CircularProgress, Stack } from "@mui/material";

export function RequireAuth({ children }) {
  const location = useLocation();
  const { user, authStatus } = useAuthenticator((context) => [context.user]);

  if (authStatus === "configuring") {
    return (
      <Stack alignItems={`center`}>
        <CircularProgress />
      </Stack>
    );
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}
