import React, { useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  InputLabel,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import {
  validatePostalCodeExists,
  validatePostalCodeFormat,
} from "../../../utils/ValidationUtils";
import ShowAlert from "../../../utils/ShowAlert";

const status = ["Active", "Inactive"];

const PracticeForm = ({ onCancel, onSubmit, selectedRow }) => {
  const [isValidatingPostalCode, setIsValidatingPostalCode] = useState(false);
  const isPostalCodeValidated = useRef(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    ehr_id: Yup.string(),
    city: Yup.string().required("City is required"),
    country_code: Yup.string().required("Country is required"),
    county: Yup.string().required("County is required"),
    postal_code: Yup.string().required("Postal Code is required"),
    state: Yup.string().required("State is required"),
    state_code: Yup.string().required("State Code is required"),
    status: Yup.string().required("Status is required"),
  });
  const { notificationState, handleClose, handleErrorNotification } =
    useNotificationHandling();

  const handleValidatePostalCode = async (postalCode, setFieldValue) => {
    setIsValidatingPostalCode(true);
    try {
      validatePostalCodeFormat(postalCode);
      const response = await validatePostalCodeExists(postalCode);

      // Update the form fields with the response values
      setFieldValue("city", response.city);
      setFieldValue("state", response.state);
      setFieldValue("state_code", response.state_code);
      setFieldValue("county", response.county);
      setFieldValue("country_code", response.country_code);
      isPostalCodeValidated.current = true;
    } catch (error) {
      handleErrorNotification(error);
    }
    setIsValidatingPostalCode(false);
  };

  const handleOnSubmit = (values) => {
    if (!isPostalCodeValidated.current) {
      handleErrorNotification({
        name: "Postal Code Validation",
        message: "Please validate your postal code",
      });
      return;
    }
    onSubmit(values);
  };

  return (
    <>
      <Formik
        initialValues={{
          name: selectedRow?.name || "",
          ehr_id: selectedRow?.ehr_id || "",
          county: selectedRow?.county || "",
          country_code: selectedRow?.country_code || "",
          state: selectedRow?.state || "",
          state_code: selectedRow?.state_code || "",
          city: selectedRow?.city || "",
          postal_code: selectedRow?.postal_code || "",
          description: selectedRow?.description || "",
          status: selectedRow?.status || "Active",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => handleOnSubmit(values)}
      >
        {({ errors, touched, handleChange, setFieldValue, values }) => (
          <Form>
            <Box
              sx={{
                maxWidth: 600,
                mx: "auto",
                p: 3,
                boxShadow: 3,
                borderRadius: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="name"
                    as={TextField}
                    label="Name"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="ehr_id"
                    as={TextField}
                    label="Ehr"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.ehr_id}
                    onChange={handleChange}
                    error={touched.ehr_id && Boolean(errors.ehr_id)}
                    helperText={touched.ehr_id && errors.ehr_id}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="country_code"
                    as={TextField}
                    label="Country"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.country_code}
                    onChange={handleChange}
                    error={touched.country_code && Boolean(errors.country_code)}
                    helperText={touched.country_code && errors.country_code}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="county"
                    as={TextField}
                    label="County"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.county}
                    onChange={handleChange}
                    error={touched.county && Boolean(errors.county)}
                    helperText={touched.county && errors.county}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="state"
                    as={TextField}
                    label="State"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.state}
                    onChange={handleChange}
                    error={touched.state && Boolean(errors.state)}
                    helperText={touched.state && errors.state}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="state_code"
                    as={TextField}
                    label="State Code"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.state_code}
                    onChange={handleChange}
                    error={touched.state_code && Boolean(errors.state_code)}
                    helperText={touched.state_code && errors.state_code}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="city"
                    as={TextField}
                    label="City"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.city}
                    onChange={handleChange}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>

                <Grid item xs={9}>
                  <Field
                    name="postal_code"
                    as={TextField}
                    label="Postal Code"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.postal_code}
                    onChange={handleChange}
                    error={touched.postal_code && Boolean(errors.postal_code)}
                    helperText={touched.postal_code && errors.postal_code}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Stack
                    justifyContent={`center`}
                    sx={{ width: "100%", height: "52px" }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      size="small"
                      sx={{ height: "80%" }}
                      onClick={() =>
                        handleValidatePostalCode(
                          values.postal_code,
                          setFieldValue
                        )
                      }
                    >
                      {isValidatingPostalCode ? (
                        <CircularProgress size={18} color="inherit" />
                      ) : (
                        "Validate"
                      )}
                    </Button>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="description"
                    as={TextField}
                    label="Description"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.description}
                    onChange={handleChange}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="status-label">Status</InputLabel>

                    <Select
                      label="Status"
                      labelId="status-label"
                      id="status"
                      name="status"
                      value={values.status}
                      onChange={(e) => {
                        setFieldValue("status", e.target.value);
                      }}
                      error={touched.status && Boolean(errors.status)}
                    >
                      {status.map((status) => (
                        <MenuItem key={status} value={status}>
                          {`${status}`}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.status && errors.status && (
                      <Typography color="error">{errors.status}</Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>

      {notificationState.showNotification && (
        <ShowAlert
          severity={notificationState.severity}
          title={notificationState.title}
          message={notificationState.message}
          description={notificationState.description}
          onClose={handleClose}
          sx={{ position: "fixed" }}
        />
      )}
    </>
  );
};

export default PracticeForm;
