export function twoAttributeSort(
  items,
  sort_attribute_1,
  sort_attribute_2,
  reverse_1 = false,
  reverse_2 = false
) {
  return items.sort((a, b) => {
    let comparison_1;
    if (
      typeof a[sort_attribute_1] === 'number' &&
      typeof b[sort_attribute_1] === 'number'
    ) {
      // Numerical comparison for sort_attribute_1
      comparison_1 = a[sort_attribute_1] - b[sort_attribute_1];
    } else {
      // String comparison for sort_attribute_1
      comparison_1 = a[sort_attribute_1].localeCompare(b[sort_attribute_1]);
    }

    // Apply reverse sort for sort_attribute_1 if specified
    if (reverse_1) {
      comparison_1 = -comparison_1;
    }

    // If the primary criteria are the same and sort_attribute_2 is provided
    if (comparison_1 === 0 && sort_attribute_2) {
      let comparison_2;
      if (
        typeof a[sort_attribute_2] === 'number' &&
        typeof b[sort_attribute_2] === 'number'
      ) {
        // Numerical comparison for sort_attribute_2
        comparison_2 = a[sort_attribute_2] - b[sort_attribute_2];
      } else {
        // String comparison for sort_attribute_2
        comparison_2 = a[sort_attribute_2].localeCompare(b[sort_attribute_2]);
      }

      // Apply reverse sort for sort_attribute_2 if specified
      if (reverse_2) {
        comparison_2 = -comparison_2;
      }

      return comparison_2;
    }

    return comparison_1;
  });
}
