function useSecurity({ menuItems, pathname, securityGroupMenus }) {
  const menuItem = menuItems.find((item) => item.menu_route === pathname);
  if (!menuItem)
    return {
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
    };

  const _securityGroupMenus = securityGroupMenus.filter(
    (security) => security.menu === menuItem.menu
  );

  const obj = {
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  };

  _securityGroupMenus.forEach((security) => {
    if (security.create_permission) {
      obj.canCreate = true;
    }
    if (security.read_permission) {
      obj.canRead = true;
    }
    if (security.update_permission) {
      obj.canUpdate = true;
    }
    if (security.delete_permission) {
      obj.canDelete = true;
    }
  });

  return obj;
}

export default useSecurity;
