import React, { useContext, useEffect, useState } from 'react';
import DataEntryForm from '../../../components/datagrid/dataEntryForm';
import ShowAlert from '../../../utils/ShowAlert';
import { useNotificationHandling } from '../../../utils/NotificationHandling';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { deleteData, getData, postData, putData } from '../../../utils/API';
import useSecurity from '../../../hooks/use-security';
import { useLocation } from 'react-router-dom';
import UserContext from '../../../contexts/UserContext';
import TreatmentStatusReasonForm from './TreatmentStatusReasonForm';

const title = 'Treatment Status Reason';

const table = 'treatment_status_reasons';

const TreatmentStatusReasonGrid = () => {
  const [loading, setLoading] = useState(true);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const [rows, setRows] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setRow] = useState(null);
  const isUpdate = Boolean(selectedRow);
  const { pathname } = useLocation();
  const { menuItems, securityGroupMenus } = useContext(UserContext);
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
    },
    {
      field: 'description',
      headerName: 'description',
      flex: 2,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      flex: 0.5,
      valueOptions: ['Active', 'Inactive'],
    },
  ];

  const handleOpenModal = (id) => {
    let selectedRow = null;
    setIsOpenModal(true);
    if (id) {
      selectedRow = rows.find((r) => r.id === id);
      setRow(selectedRow);
    }
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    if (selectedRow) {
      setRow(null);
    }
  };

  async function deleteRow(id, row, oldRows) {
    const body = {
      id: row.id,
    };

    try {
      await deleteData(table, body);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      throw error;
    }
  }

  const getRows = async () => {
    setLoading(true);
    try {
      const response = await getData(table);
      setRows(response);
    } catch (error) {
      handleErrorNotification(error);
    }
    setLoading(false);
  };

  const handleOnSubmit = async (values) => {
    const rowToSave = {
      ...values,
    };

    setLoading(true);
    handleModalClose();

    try {
      if (!isUpdate) {
        await postData(table, rowToSave);
        await getRows();
      } else {
        await putData(table, { ...rowToSave, id: selectedRow.id });
        await getRows();
      }
    } catch (error) {
      handleErrorNotification(error);
    }
    setLoading(false);
  };

  const initFetch = async () => {
    try {
      await Promise.all([getRows()]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initFetch();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntryForm
        title={title}
        columns={columns}
        rows={rows}
        loading={loading}
        onAdd={handleOpenModal}
        onEdit={handleOpenModal}
        onDeleteRow={deleteRow}
        filterKey={'treatment_programs'}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />
      <Dialog
        open={isOpenModal}
        onClose={handleModalClose}
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
          },
        }}
      >
        <DialogTitle>{`${isUpdate ? 'Edit' : 'Add'} ${title}`}</DialogTitle>
        <DialogContent sx={{ width: '100%', maxWidth: '600px' }}>
          <TreatmentStatusReasonForm
            onSubmit={handleOnSubmit}
            onClose={handleClose}
            selectedRow={selectedRow}
            onCancel={handleModalClose}
            rows={rows}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TreatmentStatusReasonGrid;
