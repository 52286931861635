import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  Autocomplete,
  InputLabel,
  Stack,
} from "@mui/material";

const validationSchema = Yup.object().shape({
  medication_id: Yup.string().required("Medication is required"),
  medication_frequency_id: Yup.string().required("Frequency is required"),
  start_date: Yup.date().nullable(),
  end_date: Yup.date()
    .nullable()
    .test(
      "is-greater-than-start_date",
      "End date must be greater than or equal to Start date",
      function (value) {
        const { start_date } = this.parent;
        if (!start_date) return true;
        if (!value) return true;

        return value >= start_date;
      }
    ),
});

const EpisodeMedicationForm = ({
  selectedMedication,
  medications,
  frequencies,
  onCancel,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{
        medication_id: selectedMedication?.medication_id || "",
        medication_frequency_id:
          selectedMedication?.medication_frequency_id || "",
        start_date: selectedMedication?.start_date || null,
        end_date: selectedMedication?.end_date || null,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({
        errors,
        touched,
        handleChange,
        setFieldValue,
        values,
        setValues,
      }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 600,
              mx: "auto",
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    slotProps={{
                      paper: {
                        sx: {
                          "& .MuiAutocomplete-listbox": {
                            zIndex: "9999",
                          },
                        },
                      },
                    }}
                    value={
                      medications.find(
                        (medication) => medication.id === values.medication_id
                      ) || null
                    }
                    options={medications}
                    filterOptions={(options, state) => {
                      const inputValue = state.inputValue;
                      if (inputValue.length < 2) {
                        return [];
                      }
                      return options.filter((option) =>
                        option.trade_name
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      );
                    }}
                    getOptionLabel={(option) => option.trade_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Medication"
                        placeholder="Type at least 2 letters to search..."
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, option) => {
                      if (option) {
                        setFieldValue("medication_id", option.id);
                      }
                    }}
                    renderOption={(props, option) => {
                      const { key, ...optionProps } = props;
                      return (
                        <Stack
                          key={option.id}
                          alignItems={`flex-start`}
                          justifyContent={`flex-start`}
                          sx={{
                            width: "100%",
                            padding: "5px",
                            paddingInline: "20px",
                            borderBottom: "1px solid #E1E1E1",
                          }}
                          {...optionProps}
                        >
                          <Typography
                            width={`100%`}
                            textAlign={`left`}
                            //   sx={{ borderLeft: "1px solid", pl: 1 }}
                          >
                            {option.trade_name}
                          </Typography>

                          <Typography
                            variant={`caption`}
                            textAlign={`left`}
                            color={"#3da58a"}
                            width={`100%`}
                          >
                            {option.strength}
                          </Typography>

                          {/* <Typography width={`100%`} variant="caption">
                            {option.dosage_form.split(";").map((el, index) => (
                              <div key={`${el}-${index}`}>{el}</div>
                            ))}
                          </Typography> */}
                        </Stack>
                      );
                    }}
                  />
                  {touched.medication_id && errors.medication_id && (
                    <Typography color="error">
                      {errors.medication_id}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="frequency-label">Frequency</InputLabel>

                  <Select
                    label="Frequency"
                    labelId="frequency-label"
                    id="frequency"
                    name="medication_frequency_id"
                    value={values.medication_frequency_id}
                    onChange={(e) =>
                      setFieldValue("medication_frequency_id", e.target.value)
                    }
                    error={
                      touched.medication_frequency_id &&
                      Boolean(errors.medication_frequency_id)
                    }
                  >
                    {frequencies.map((frequency) => (
                      <MenuItem key={frequency.id} value={frequency.id}>
                        {`${frequency.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.medication_frequency_id &&
                    errors.medication_frequency_id && (
                      <Typography color="error">
                        {errors.medication_frequency_id}
                      </Typography>
                    )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="start_date"
                  as={TextField}
                  label="Start Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={values.start_date}
                  onChange={handleChange}
                  error={touched.start_date && Boolean(errors.start_date)}
                  helperText={touched.start_date && errors.start_date}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="end_date"
                  as={TextField}
                  label="End Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={values.end_date}
                  onChange={handleChange}
                  error={touched.end_date && Boolean(errors.end_date)}
                  helperText={touched.end_date && errors.end_date}
                />
              </Grid>

              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EpisodeMedicationForm;
