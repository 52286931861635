import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Stack,
  CircularProgress,
  Typography,
  Divider,
} from "@mui/material";
import _ from "lodash";
import { getData } from "../../utils/API";
import BarChart from "../charts/BarChart";
import { getColorByIndex } from "../../utils/Color";
import ShowAlert from "../../utils/ShowAlert";
import { useNotificationHandling } from "../../utils/NotificationHandling";

const TabStructure = ({
  tabs,
  title,
  tableName,
  countByKey,
  displayDataLabels = true,
  fieldOptionsByTab = [],
  percentageBasedOn = "groupLength",
}) => {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const [tabValue, setTabValue] = useState(tabs[0]?.value);
  const [fieldOptions, setFieldOptions] = useState(
    fieldOptionsByTab[tabs[0]?.value]
  );
  const [selectedFields, setSelectedFields] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const totalLength = data.length;

  const initializeSelectedFields = (options, existingSelectedFields = {}) => {
    return options.reduce((acc, field) => {
      // Automatically select all options for newly introduced fields
      acc[field.name] =
        existingSelectedFields[field.name] &&
        existingSelectedFields[field.name].length > 0
          ? existingSelectedFields[field.name]
          : field.options.map((option) => option);
      return acc;
    }, {});
  };

  const handleTabChange = (event) => {
    const {
      target: { value },
    } = event;
    setTabValue(value);
    const updatedFieldOptions = fieldOptionsByTab[value].map((field) => {
      const uniqueValues = _.uniq(data.map((item) => item[field.name]))
        .filter(Boolean)
        .sort();
      return { ...field, options: uniqueValues };
    });

    setFieldOptions(updatedFieldOptions);

    // Initialize or update selected fields with "Select All" for new fields
    setSelectedFields((prevSelectedFields) =>
      initializeSelectedFields(updatedFieldOptions, prevSelectedFields)
    );
  };

  const handleFieldChange = (event, field) => {
    const {
      target: { value },
    } = event;
    if (value.includes("all")) {
      if (
        selectedFields[field].length ===
        fieldOptions.find((opt) => opt.name === field).options.length
      ) {
        setSelectedFields({ ...selectedFields, [field]: [] });
      } else {
        setSelectedFields({
          ...selectedFields,
          [field]: fieldOptions.find((opt) => opt.name === field).options,
        });
      }
    } else if (value.includes("removeAll")) {
      setSelectedFields({ ...selectedFields, [field]: [] });
    } else {
      setSelectedFields({ ...selectedFields, [field]: value });
    }
  };
  const formatPercentage = (num) => {
    return (Math.round(num * 10) / 10).toFixed(1).replace(/\.0+$/, "");
  };
  const groupByAndCount = (data, groupByKey) => {
    const groupedData = _.groupBy(data, groupByKey);

    // Dynamically determine the custom order based on the grouped keys
    const customOrder = Object.keys(groupedData);

    // Sort the customOrder array dynamically
    // Example: If the keys contain both dates and letters, you might want to sort dates first, then letters
    customOrder.sort((a, b) => {
      const dateRegex = /^\d{4}$/; // Matches a year (e.g., 2022)
      const quarterRegex = /^\d{4}-Q\d$/; // Matches a year and quarter (e.g., 2022-Q1)

      // Helper function to parse quarter strings
      const parseQuarter = (str) => {
        const [year, quarter] = str.split("-Q");
        return new Date(year, (parseInt(quarter) - 1) * 3); // Convert quarter to a month (0 for Jan, 3 for Apr, etc.)
      };

      if (a.match(quarterRegex) && b.match(quarterRegex)) {
        return parseQuarter(a) - parseQuarter(b);
      } else if (a.match(quarterRegex)) {
        return -1; // Quarters come before letters
      } else if (b.match(quarterRegex)) {
        return 1; // Quarters come before letters
      } else if (a.match(dateRegex) && b.match(dateRegex)) {
        return new Date(a) - new Date(b);
      } else if (a.match(dateRegex)) {
        return -1; // Dates come before letters
      } else if (b.match(dateRegex)) {
        return 1; // Dates come before letters
      } else {
        return a.localeCompare(b); // Alphabetical order
      }
    });

    // Now sort the grouped keys according to this dynamic order
    const sortedGroupedData = customOrder.reduce((acc, key) => {
      acc[key] = groupedData[key];
      return acc;
    }, {});

    return Object.keys(sortedGroupedData).map((key) => {
      const countByKeyCounts = _.countBy(sortedGroupedData[key], countByKey);
      const groupLength = sortedGroupedData[key].length;
      const baseLength =
        percentageBasedOn === "groupLength" ? groupLength : totalLength;

      const percentage = (groupLength / baseLength) * 100;
      // Convert counts to percentages

      const percentageByKeyCounts = Object.keys(countByKeyCounts).reduce(
        (acc, countKey) => {
          acc[countKey] = formatPercentage(
            (countByKeyCounts[countKey] / baseLength) * 100
          );
          return acc;
        },
        {}
      );

      return {
        key,
        count: groupLength,
        percentage: formatPercentage(percentage),
        ...percentageByKeyCounts,
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fetchedData = await getData(tableName, { practice_id: 1 });
        setData(fetchedData);

        const initialFieldOptions = fieldOptionsByTab[tabs[0]?.value].map(
          (field) => {
            const uniqueValues = _.uniq(
              fetchedData.map((item) => item[field.name])
            )
              .filter(Boolean)
              .sort();
            return { ...field, options: uniqueValues };
          }
        );

        setFieldOptions(initialFieldOptions);

        // Initialize selected fields with "Select All"
        setSelectedFields(initializeSelectedFields(initialFieldOptions));
      } catch (error) {
        handleErrorNotification(error);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [tableName]);

  const filteredData = data.filter((item) =>
    Object.keys(selectedFields).every((key) =>
      selectedFields[key].includes(item[key])
    )
  );

  const groupedData = groupByAndCount(filteredData, tabValue);
  const subtitle = `(n=${filteredData.length.toLocaleString()})`;
  const uniqueCountByKeyData = _.uniq(
    data.map((item) => item[countByKey])
  ).filter(Boolean);

  const chartData = {
    labels: groupedData.map((item) => item.key),
    datasets: uniqueCountByKeyData.map((data, index) => ({
      label: data,
      data: groupedData.map((item) => item[data] || 0),
      backgroundColor: getColorByIndex(index),
      borderColor: getColorByIndex(index).replace("0.8", "1"),
      borderWidth: 1,
    })),
  };

  const xTitle = tabValue
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  if (loading) {
    return (
      <Stack alignItems={`center`} py={3}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack direction={`row`} justifyContent={`center`} gap={5}>
      <Stack gap={2}>
        <Stack gap={1}>
          <Typography variant="h6">Group by</Typography>

          <FormControl
            variant="outlined"
            style={{ minWidth: 200 }}
            size="small"
          >
            <InputLabel>Group by</InputLabel>
            <Select
              value={tabValue}
              onChange={handleTabChange}
              label="Group by"
            >
              {tabs.map((tab) => (
                <MenuItem key={tab.value} value={tab.value}>
                  {tab.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Divider />

        <Stack gap={2}>
          <Typography variant="h6">Filters</Typography>

          {fieldOptions.map((field, fieldIndex) => (
            <FormControl
              key={fieldIndex}
              variant="outlined"
              style={{ minWidth: 200 }}
              size="small"
            >
              <InputLabel>{`${field.label}`}</InputLabel>
              <Select
                multiple
                label={field.label}
                value={selectedFields[field.name]}
                onChange={(e) => handleFieldChange(e, field.name)}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return "";
                  } else if (
                    selected.length === field.options.length ||
                    selected.includes("all")
                  ) {
                    return "All";
                  } else if (selected.length > 1) {
                    return "Multiple";
                  } else {
                    return selected[0];
                  }
                }}
              >
                <MenuItem value="all">
                  <Checkbox
                    checked={
                      selectedFields[field.name].length === field.options.length
                    }
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                <MenuItem value="removeAll">
                  <Checkbox checked={selectedFields[field.name].length === 0} />
                  <ListItemText primary="Remove All" />
                </MenuItem>
                {field.options.map((option, optionIndex) => (
                  <MenuItem key={optionIndex} value={option}>
                    <Checkbox
                      checked={selectedFields[field.name].indexOf(option) > -1}
                    />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </Stack>
      </Stack>

      <BarChart
        legendPosition="right"
        data={chartData}
        yTitle="Count"
        xTitle={xTitle}
        yTitleDisplay={false}
        title={title}
        subtitle={subtitle}
        displayTitle
        displaySubtitle
        displayPercent
        displayDataLabels={displayDataLabels}
      />
    </Stack>
  );
};

export default TabStructure;
