import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  InputLabel,
  TextField,
} from "@mui/material";

const status = ["Active", "Inactive"];

const TreatmentProgramForm = ({ onCancel, onSubmit, selectedRow, rows }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required()
      .test("is-exist", "This name is already exist", function (value) {
        const isExist = rows
          .filter((row) => row.id !== selectedRow?.id)
          .some((row) => row.name === value);

        return !isExist;
      }),
    acronym: Yup.string().required(),
    description: Yup.string(),
    status: Yup.string().required("Status is required"),
  });

  return (
    <Formik
      initialValues={{
        name: selectedRow?.name || "",
        acronym: selectedRow?.acronym || "",
        description: selectedRow?.description || "",
        status: selectedRow?.status || "Active",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, touched, handleChange, setFieldValue, values }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 600,
              mx: "auto",
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Field
                    name="name"
                    as={TextField}
                    label="Name"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Field
                    name="acronym"
                    as={TextField}
                    label="acronym"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.acronym}
                    onChange={handleChange}
                    error={touched.acronym && Boolean(errors.acronym)}
                    helperText={touched.acronym && errors.acronym}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Field
                    name="description"
                    as={TextField}
                    label="Description"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.description}
                    onChange={handleChange}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>

                  <Select
                    label="Status"
                    labelId="status-label"
                    id="status"
                    name="status"
                    value={values.status}
                    onChange={(e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    error={touched.status && Boolean(errors.status)}
                  >
                    {status.map((status) => (
                      <MenuItem key={status} value={status}>
                        {`${status}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.status && errors.status && (
                    <Typography color="error">{errors.status}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TreatmentProgramForm;
