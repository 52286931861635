import React, { useContext, useEffect, useState } from "react";
import DataEntryForm from "../../../components/datagrid/dataEntryForm";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { deleteData, getData, postData, putData } from "../../../utils/API";
import MenuForm from "./SecurityGroupForm";
import { useLocation } from "react-router-dom";
import useSecurity from "../../../hooks/use-security";
import UserContext from "../../../contexts/UserContext";

const title = "Security Groups";

const table = "security_groups";

const SecurityGroupGrid = () => {
  const [loading, setLoading] = useState(true);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const [rows, setRows] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setRow] = useState(null);
  const isUpdate = Boolean(selectedRow);
  const { pathname } = useLocation();
  const { menuItems, securityGroupMenus } = useContext(UserContext);
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      type: "singleSelect",
      flex: 0.5,
      valueOptions: rows.map((row) => row.name),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      flex: 0.5,
      valueOptions: ["Active", "Inactive"],
    },
  ];

  const handleOpenModal = (id) => {
    let selectedRow = null;
    setIsOpenModal(true);
    if (id) {
      selectedRow = rows.find((r) => r.id === id);
      setRow(selectedRow);
    }
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    if (selectedRow) {
      setRow(null);
    }
  };

  async function deleteRow(id, row, oldRows) {
    setLoading(true);
    const body = {
      id: row.id,
    };

    try {
      await putData(table, body);
      setRows(oldRows.filter((r) => r.id !== id));
      setLoading(false);
      return "Deleted";
    } catch (error) {
      throw error;
    }
  }

  const getRows = async () => {
    setLoading(true);
    try {
      let response = await getData(table, { deleted: false });
      response = response.sort((a, b) => a.menu_order - b.menu_order);
      setRows(response);
    } catch (error) {
      handleErrorNotification(error);
    }
    setLoading(false);
  };

  const handleOnSubmit = async (values) => {
    const rowToSave = { ...values, menu_icon: "na" };
    setLoading(true);
    handleModalClose();
    try {
      if (!isUpdate) {
        let menuOrder = rows[rows.length - 1]?.menu_order || 0;
        menuOrder++;

        await postData(table, { ...rowToSave, menu_order: menuOrder });
        await getRows();
      } else {
        await putData(table, { ...rowToSave, id: selectedRow.id });
        await getRows();
      }
    } catch (error) {
      handleErrorNotification(error);
    }
    setLoading(false);
  };

  const initFetch = async () => {
    try {
      await Promise.all([getRows()]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initFetch();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntryForm
        title={title}
        columns={columns}
        rows={rows}
        loading={loading}
        onAdd={handleOpenModal}
        onEdit={handleOpenModal}
        onDeleteRow={deleteRow}
        filterKey={"menu-components"}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />
      <Dialog open={isOpenModal} onClose={handleModalClose}>
        <DialogTitle>{`${isUpdate ? "Edit" : "Add"} ${title}`}</DialogTitle>

        <DialogContent sx={{ width: "100%", maxWidth: "500px" }}>
          <MenuForm
            onSubmit={handleOnSubmit}
            onClose={handleClose}
            selectedRow={selectedRow}
            onCancel={handleModalClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SecurityGroupGrid;
