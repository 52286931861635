import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const status = ["Active", "Inactive"];

const SecurityGroupsMenuForm = ({
  onCancel,
  onSubmit,
  securityGroups,
  menus,
  selectedRow,
  rows,
}) => {
  const validationSchema = Yup.object().shape({
    menu_id: Yup.string().required("Menu is required"),
    security_group_id: Yup.string()
      .required("Security Group is required")
      .test("is-exist", function (value) {
        const { menu_id } = this.parent;
        const menu = menus.find((menu) => menu.id === Number(menu_id));
        const securityGroup = securityGroups.find(
          (securityGroup) => securityGroup.id === Number(value)
        );

        const isExist = rows
          .filter((row) => row.id !== selectedRow?.id)
          .some((row) => {
            return (
              row.menu_id === Number(menu_id) &&
              row.security_group_id === Number(value)
            );
          });

        if (isExist) {
          return this.createError({
            path: this.path,
            message: `${menu.name} and ${securityGroup.name} already exists`,
          });
        }

        return true;
      }),
    create: Yup.boolean(),
    read: Yup.boolean(),
    update: Yup.boolean(),
    delete: Yup.boolean(),
    status: Yup.string().required("Status is required"),
  });

  return (
    <Formik
      initialValues={{
        menu_id: selectedRow?.menu_id || "",
        security_group_id: selectedRow?.security_group_id || "",
        create_permission: selectedRow?.create_permission || false,
        read_permission: selectedRow?.read_permission || false,
        update_permission: selectedRow?.update_permission || false,
        delete_permission: selectedRow?.delete_permission || false,
        status: selectedRow?.status || "Active",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, touched, handleChange, setFieldValue, values }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 600,
              mx: "auto",
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="menu-label">Menu</InputLabel>

                  <Select
                    label="Menu"
                    labelId="menu-label"
                    id="menu_id"
                    name="menu_id"
                    value={values.menu_id}
                    onChange={(e) => {
                      setFieldValue("menu_id", e.target.value);
                    }}
                    error={touched.menu_id && Boolean(errors.menu_id)}
                  >
                    {menus.map((menu) => (
                      <MenuItem key={menu.id} value={menu.id}>
                        {menu.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.menu_id && errors.menu_id && (
                    <Typography
                      color="error"
                      variant="caption"
                      sx={{ mx: "14px" }}
                    >
                      {errors.menu_id}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="security-group-label">
                    Security Group
                  </InputLabel>

                  <Select
                    label="Security Group"
                    labelId="security-group-label"
                    id="security_group_id"
                    name="security_group_id"
                    value={values.security_group_id}
                    onChange={(e) => {
                      setFieldValue("security_group_id", e.target.value);
                    }}
                    error={
                      touched.security_group_id &&
                      Boolean(errors.security_group_id)
                    }
                  >
                    {securityGroups.map((securityGroup) => (
                      <MenuItem
                        key={`security-group${securityGroup.id}`}
                        value={securityGroup.id}
                      >
                        {`${securityGroup.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.security_group_id && errors.security_group_id && (
                    <Typography
                      color="error"
                      variant="caption"
                      sx={{ mx: "14px" }}
                    >
                      {errors.security_group_id}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>

                  <Select
                    label="Status"
                    labelId="status-label"
                    id="status"
                    name="status"
                    value={values.status}
                    onChange={(e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    error={touched.status && Boolean(errors.status)}
                  >
                    {status.map((status) => (
                      <MenuItem key={status} value={status}>
                        {`${status}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.status && errors.status && (
                    <Typography color="error">{errors.status}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.create_permission}
                      onChange={(e) =>
                        setFieldValue("create_permission", e.target.checked)
                      }
                    />
                  }
                  label="Create"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.update_permission}
                      onChange={(e) =>
                        setFieldValue("update_permission", e.target.checked)
                      }
                    />
                  }
                  label="Update"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.read_permission}
                      onChange={(e) =>
                        setFieldValue("read_permission", e.target.checked)
                      }
                    />
                  }
                  label="Read"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.delete_permission}
                      onChange={(e) =>
                        setFieldValue("delete_permission", e.target.checked)
                      }
                    />
                  }
                  label="Delete"
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SecurityGroupsMenuForm;
