import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  InputLabel,
} from "@mui/material";

const PracticeOfficeTreatmentForm = ({
  offices,
  programs,
  populations,
  treatmentTypes,
  selectedRow,
  onCancel,
  onSubmit,
  rows,
}) => {
  const validationSchema = Yup.object().shape({
    office_id: Yup.string().required("Location is required"),
    treatment_type_id: Yup.string()
      .required("Treatment Type is required")
      .test(
        "unique-combo2",
        "This combination of Office, Type, Program, and Population already exists.",
        function (value) {
          const { office_id, treatment_program_id, population_id } =
            this.parent;

          const row = rows.find(
            (row) =>
              Number(row.office_id) === Number(office_id) &&
              Number(row.treatment_type_id) === Number(value) &&
              (treatment_program_id
                ? Number(row.treatment_program_id) ===
                  Number(treatment_program_id)
                : row.treatment_program_id === null) && // Strict check for null values
              (population_id
                ? Number(row.population_id) === Number(population_id)
                : row.population_id === null) // Strict check for null values
          );

          // If a matching row is found, return false (validation fails)
          return !row;
        }
      ),
    treatment_program_id: Yup.string().test(
      "unique-combo2",
      "Treatment Program is required",
      function (value) {
        const { treatment_type_id } = this.parent;
        const _programs = programs.filter(
          (program) => program.treatment_type_id === Number(treatment_type_id)
        );
        if (!value && treatment_type_id && _programs.length > 0) {
          return false;
        }

        return true;
      }
    ),
    population_id: Yup.string(),
  });

  return (
    <Formik
      initialValues={{
        office_id: selectedRow?.office_id || "",
        treatment_program_id: selectedRow?.treatment_program_id || "",
        population_id: selectedRow?.population_id || "",
        treatment_type_id: selectedRow?.treatment_type_id || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 600,
              mx: "auto",
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="location-label">Location</InputLabel>

                  <Select
                    label="Location"
                    labelId="location-label"
                    id="office"
                    name="office_id"
                    value={values.office_id}
                    onChange={(e) => {
                      setFieldValue("office_id", e.target.value);
                    }}
                    error={touched.office_id && Boolean(errors.office_id)}
                  >
                    {offices.map((office, index) => (
                      <MenuItem key={`${office.id}-${index}`} value={office.id}>
                        {`${office.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.office_id && errors.office_id && (
                    <Typography color="error">{errors.office_id}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="treatment-type-label">
                    Treatment Type
                  </InputLabel>

                  <Select
                    label="Treatment Type"
                    labelId="treatment-type-label"
                    id="treatment_type_id"
                    name="treatment_type_id"
                    value={values.treatment_type_id}
                    onChange={(e) => {
                      setFieldValue("treatment_type_id", e.target.value);
                      const _programs = programs.filter(
                        (program) =>
                          program.treatment_type_id ===
                            Number(e.target.value) && program.treatment_program
                      );

                      if (_programs.length === 1) {
                        setFieldValue(
                          "treatment_program_id",
                          _programs[0].treatment_program_id
                        );
                      } else {
                        setFieldValue("treatment_program_id", "");
                      }
                    }}
                    error={
                      touched.treatment_type_id &&
                      Boolean(errors.treatment_type_id)
                    }
                  >
                    {treatmentTypes.map((treatmentType, index) => (
                      <MenuItem
                        key={`${treatmentType.id}-${index}`}
                        value={treatmentType.id}
                      >
                        {`${treatmentType.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.treatment_type_id && errors.treatment_type_id && (
                    <Typography color="error">
                      {errors.treatment_type_id}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="treatment-program-label">Program</InputLabel>

                  <Select
                    label="Program"
                    labelId="treatment-program-label"
                    id="treatment_program_id"
                    name="treatment_program_id"
                    value={values.treatment_program_id}
                    onChange={(e) => {
                      setFieldValue("treatment_program_id", e.target.value);
                    }}
                    disabled={!values.treatment_type_id}
                    error={
                      touched.treatment_program_id &&
                      Boolean(errors.treatment_program_id)
                    }
                  >
                    {programs
                      .filter(
                        (program) =>
                          program.treatment_type_id ===
                            Number(values.treatment_type_id) &&
                          program.treatment_program
                      )
                      .map((program, index) => (
                        <MenuItem
                          key={`${program.id}-${index}`}
                          value={program.treatment_program_id}
                        >
                          {`${program.treatment_program}`}
                        </MenuItem>
                      ))}
                  </Select>
                  {touched.treatment_program_id &&
                    errors.treatment_program_id && (
                      <Typography color="error">
                        {errors.treatment_program_id}
                      </Typography>
                    )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="population-label">Population</InputLabel>

                  <Select
                    label="Population"
                    labelId="population-label"
                    id="population_id"
                    name="population_id"
                    value={values.population_id}
                    onChange={(e) => {
                      setFieldValue("population_id", e.target.value);
                    }}
                    error={
                      touched.population_id && Boolean(errors.population_id)
                    }
                  >
                    {populations.map((population, index) => (
                      <MenuItem
                        key={`${population.id}-${index}`}
                        value={population.id}
                      >
                        {`${population.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.population_id && errors.population_id && (
                    <Typography color="error">
                      {errors.population_id}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PracticeOfficeTreatmentForm;
