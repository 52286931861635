import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';
import logger from '../../utils/logger';

export default function EpisodeTabNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const episodeOfCare = location.state?.episodeOfCare;
  const [value, setValue] = useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isDark = theme.palette.mode === 'dark';

  useEffect(() => {
    switch (location.pathname) {
      case '/patient/episode/dashboard':
        setValue(0);
        break;
      case '/patient/episode/tms_treatment_plans':
        setValue(1);
        break;
      case '/patient/episode/test_scores':
        setValue(2);
        break;
      case '/patient/episode/medications':
        setValue(3);
        break;
      case '/patient/episode/diagnosis':
        setValue(4);
        break;
      default:
        setValue(0);
    }
  }, [location.pathname]);

  //logger.info(`EpisodeTabNavigation value is ${value}`);

  const handleChange = (event, newValue) => {
    setValue(newValue); // Update the value state here
    let route = '/';
    switch (newValue) {
      case 0:
        route = '/patient/episode/dashboard';
        break;
      case 1:
        route = '/patient/episode/tms_treatment_plans';
        break;
      case 2:
        route = '/patient/episode/test_scores';
        break;
      case 3:
        route = '/patient/episode/medications';
        break;
      case 4:
        route = '/patient/episode/diagnosis';
        break;
      default:
        route = '/patient/episodes';
        break;
    }
    navigate(route, { state: { episodeOfCare } });
  };

  return (
    <Box
      sx={{
        borderBottom: 1,
        mx: -3,
        mt: -3,
        mb: 3,
        borderColor: 'divider',
        boxShadow: 'none',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        variant='fullWidth'
        sx={{
          backgroundColor: colors.primary[600],
          '& .MuiButtonBase-root': {
            color: colors.grey[10],
          },
          '& .MuiTab-root.Mui-selected': {
            fontWeight: 700,
            color: colors.grey[10],
          },
          '& .MuiTabs-indicator': {
            backgroundColor: colors.grey[10],
          },
        }}
      >
        <Tab label='DASHBOARD' />
        <Tab label='TREATMENT PLANS' />
        {/* <Tab label='TREATMENT SESSIONS' /> */}
        <Tab label='TEST SCORES' />
        <Tab label='MEDICATIONS' />
        <Tab label='DIAGNOSIS' />
      </Tabs>
    </Box>
  );
}
