import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Auth } from "aws-amplify";
import { getData } from "../utils/API";
import { sortMenuItems } from "../utils/menu";
import { useNotificationHandling } from "../utils/NotificationHandling";
import ShowAlert from "../utils/ShowAlert";

const staticDefaultUserValue = {
  practiceId: "",
  email: "",
  firstName: "",
  lastName: "",
  securityGroupMenus: [],
};

const UserContext = createContext(staticDefaultUserValue);

function UserProvider({ children }) {
  const [user, setUser] = useState(staticDefaultUserValue);
  const [menuItems, setMenuItems] = useState([]);
  const [menus, setMenus] = useState([]);
  const [securityGroupMenus, setSecurityGroupMenus] = useState([]);
  const [defaultUserData, setDefaultUserData] = useState(
    staticDefaultUserValue
  );
  const [loading, setLoading] = useState(true);
  const securities = useRef([]);
  const securityMapRef = useRef(null);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const getUserGroups = async () => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const groups = idToken.payload["cognito:groups"];

      return groups;
    } catch (error) {
      console.error("Error getting user groups:", error);
    }
  };

  const updateMenus = useCallback(async () => {
    try {
      let securityGroupMenus = await getData("security_group_menus", {
        status: "Active",
      });
      securityGroupMenus = securityGroupMenus.filter((menu) =>
        securities.current.includes(menu.security_group)
      );
      const securityMap = new Map();
      securityGroupMenus.forEach((security) => {
        if (security.read_permission) {
          securityMap.set(security.menu_id, security.menu);
        }
      });
      securityMapRef.current = securityMap;
      setSecurityGroupMenus(securityGroupMenus);

      let menus = await getData("menus", {
        deleted: false,
        status: "Active",
      });
      menus = menus.filter((menu) =>
        Array.from(securityMap.values()).includes(menu.name)
      );
      menus = menus.sort((a, b) => {
        // Sort by menu_order
        if (a.menu_order < b.menu_order) return -1;
        if (a.menu_order > b.menu_order) return 1;
        return 0;
      });

      setMenus(menus);
      await updateMenuItems();
    } catch (error) {
      throw error;
    }
  }, []);

  const updateMenuItems = useCallback(async () => {
    if (!securityMapRef.current) return;
    let menuItems = await getData("menu_items", {
      deleted: false,
      status: "Active",
    });
    menuItems = menuItems.filter((menuItem) =>
      Array.from(securityMapRef.current.values()).includes(menuItem.menu)
    );
    const sortedMenuItems = sortMenuItems(menuItems);
    setMenuItems(sortedMenuItems);
  }, []);

  const updateUserData = useCallback(
    async (currentUser) => {
      try {
        const emailAttribute = currentUser.attributes.email;
        // Retrieve custom attributes from the user attributes
        const customAttributes = currentUser.attributes; // This contains all user attributes

        // Example: Retrieve a specific custom attribute
        const practice_id = customAttributes["custom:practice_id"];
        const _securities = await getUserGroups();
        const practice = await getData("practices", {});
        securities.current = _securities;

        const userDataValues = {
          practice_name: practice[0].name,
          practiceId: practice_id,
          email: emailAttribute,
          firstName: currentUser.attributes.given_name,
          lastName: currentUser.attributes.family_name,
          securities,
        };

        // Check if user data is different from current state
        if (JSON.stringify(userDataValues) !== JSON.stringify(user)) {
          setUser(userDataValues);
          setDefaultUserData(userDataValues);
        }
        await updateMenus();
      } catch (error) {
        handleErrorNotification(error);
      }
    },
    [handleErrorNotification, updateMenus, user]
  );

  const setUserData = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await updateUserData(currentUser);
    } catch (error) {}
    setLoading(false);
  }, [updateUserData]);

  const userInfo = useMemo(() => {
    return {
      ...defaultUserData,
      ...user,
      loading,
      menuItems,
      menus,
      securityGroupMenus,
      updateMenus,
      setUserData,
      updateMenuItems,
    };
  }, [
    defaultUserData,
    menuItems,
    menus,
    securityGroupMenus,
    loading,
    user,
    setUserData,
    updateMenus,
    updateMenuItems,
  ]);

  useEffect(() => {
    setUserData();
  }, []);

  return (
    <UserContext.Provider value={userInfo}>
      {notificationState.showNotification && (
        <ShowAlert
          severity={notificationState.severity}
          title={notificationState.title}
          message={notificationState.message}
          description={notificationState.description}
          onClose={handleClose}
        />
      )}

      {children}
    </UserContext.Provider>
  );
}

export { UserProvider };
export default UserContext;
