import React, { useContext } from "react";
import ReactPlayer from "react-player";
import UserContext from "../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import styles from "./VideoPlayer.module.css";

const VideoPlayer = () => {
  const { pathname } = useLocation();
  const { menuItems } = useContext(UserContext);
  const menuItem = menuItems.find((item) => item.menu_route === pathname);
  const url = menuItem?.video_link;

  if (!url)
    return (
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        No Video
      </Typography>
    );

  const isSynthesia = url.includes("share.synthesia.io/embeds");

  if (isSynthesia) {
    return (
      <div
        style={{
          width: "100%",
          margin: "auto",
          maxWidth: "1350px",
          position: "relative",
          aspectRatio: "1920/1080",
        }}
      >
        <iframe
          src={url}
          loading="lazy"
          title="video player - OutcomesAI"
          allowfullscreen
          allow="encrypted-media; fullscreen;"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            border: "none",
            padding: 0,
            margin: 0,
            overflow: "hidden",
          }}
        ></iframe>
      </div>
    );
  }

  return (
    <Box className={styles["base"]}>
      <ReactPlayer width={`100%`} height={`auto`} url={url} controls />
    </Box>
  );
};

export default VideoPlayer;
