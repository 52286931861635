import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  InputLabel,
} from "@mui/material";
import dayjs from "dayjs";

const EpisodeTestScoreForm = ({
  questionnaireObjects,
  questionnaireScoreIntervalObjects,
  scoreOptions,
  selectedRow,
  questionnaireCriteriaObjects,
  onCancel,
  onSubmit,
  rows,
  startDate,
  endDate,
  endDateFound,
}) => {
  const validationSchema = Yup.object().shape({
    questionnaire_id: Yup.string().required("Questionnaire is required"),
    questionnaire_score_interval_id: Yup.string()
      .required("Score Interval is required")
      .test(
        "unique-combo1",
        "This combination of Questionnaire and Score Interval already exists.",
        function (value) {
          const { questionnaire_id } = this.parent;
          const questionnaire_name = questionnaireObjects.find(
            (obj) => Number(obj.id) === Number(questionnaire_id)
          )?.name;
          const scoreInterval = questionnaireScoreIntervalObjects.find(
            (obj) => Number(obj.id) === Number(value)
          )?.name;
          const isUnique = rows
            .filter((row) => row.id !== selectedRow?.id)
            .every(
              (row) =>
                row.questionnaire_name !== questionnaire_name ||
                row.questionnaire_score_interval_name !== scoreInterval
            );

          return isUnique;
        }
      ),
    score: Yup.string()
      .required("Score is required")
      .test("is-score-in-range", function (value) {
        const { questionnaire_id } = this.parent;
        const questionnaire_name = questionnaireObjects.find(
          (obj) => Number(obj.id) === Number(questionnaire_id)
        )?.name;

        const criteriaObject = questionnaireCriteriaObjects.find(
          (obj) => obj.questionnaire_name === questionnaire_name
        );

        if (!criteriaObject) return true; // If no criteria found, skip validation

        if (
          value < criteriaObject.min_score ||
          value > criteriaObject.max_score
        ) {
          return this.createError({
            path: this.path,
            message: `Score must be between ${criteriaObject.min_score} and ${criteriaObject.max_score}.`,
          });
        }

        return true;
      }),
    score_date: Yup.date()
      .required("Score Date is required")
      .test(
        "is-not-future-date",
        "Score Date cannot be in the future",
        (value) => {
          const today = new Date();
          return value <= today;
        }
      )
      .test(
        "is-after-start-date",
        "Score Date cannot be before Episode of Care Start Date.",
        function (value) {
          const scoreDateObject = dayjs(value).format("YYYY-MM-DD");
          return (
            new Date(scoreDateObject).getTime() >= new Date(startDate).getTime()
          );
        }
      )
      .test(
        "is-before-end-date",
        "Score Date cannot be after Episode of Care End Date.",
        function (value) {
          if (!endDateFound) return true;
          const scoreDateObject = dayjs(value).format("YYYY-MM-DD");
          return new Date(scoreDateObject) <= new Date(endDate);
        }
      ),
  });

  return (
    <Formik
      initialValues={{
        questionnaire_id: selectedRow?.questionnaire_id || "",
        questionnaire_score_interval_id:
          selectedRow?.questionnaire_score_interval_id || "",
        score: selectedRow?.score || "",
        score_date: selectedRow?.score_date || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, touched, handleChange, setFieldValue, values }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 600,
              mx: "auto",
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="questionnaire-label">
                    Questionnaire
                  </InputLabel>

                  <Select
                    label="questionnaire"
                    labelId="questionnaire-label"
                    id="questionnaire_id"
                    name="questionnaire_id"
                    value={values.questionnaire_id}
                    onChange={(e) => {
                      setFieldValue("questionnaire_id", e.target.value);
                    }}
                    error={
                      touched.questionnaire_id &&
                      Boolean(errors.questionnaire_id)
                    }
                  >
                    {questionnaireObjects.map((questionnaire) => (
                      <MenuItem key={questionnaire.id} value={questionnaire.id}>
                        {`${questionnaire.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.questionnaire_id && errors.questionnaire_id && (
                    <Typography color="error">
                      {errors.questionnaire_id}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="questionnaire-score-interval-label">
                    Questionnaire Score Interval
                  </InputLabel>

                  <Select
                    label="Questionnaire Score Interval"
                    labelId="questionnaire-score-interval-label"
                    id="questionnaire_score_interval_id"
                    name="questionnaire_score_interval_id"
                    value={values.questionnaire_score_interval_id}
                    onChange={(e) => {
                      setFieldValue(
                        "questionnaire_score_interval_id",
                        e.target.value
                      );
                    }}
                    error={
                      touched.questionnaire_score_interval_id &&
                      Boolean(errors.questionnaire_score_interval_id)
                    }
                  >
                    {questionnaireScoreIntervalObjects.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {`${item.name}`}
                      </MenuItem>
                    ))}
                  </Select>

                  {touched.questionnaire_score_interval_id &&
                    errors.questionnaire_score_interval_id && (
                      <Typography color="error">
                        {errors.questionnaire_score_interval_id}
                      </Typography>
                    )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="score_date"
                  as={TextField}
                  label="Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={values.score_date}
                  onChange={handleChange}
                  error={touched.score_date && Boolean(errors.score_date)}
                  helperText={touched.score_date && errors.score_date}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="score">Score</InputLabel>

                  <Select
                    label="Score"
                    labelId="score"
                    id="score"
                    name="score"
                    value={values.score}
                    onChange={(e) => {
                      setFieldValue("score", e.target.value);
                    }}
                    error={touched.score && Boolean(errors.score)}
                  >
                    {scoreOptions.map((score, index) => (
                      <MenuItem key={`${score}-${index}`} value={score}>
                        {`${score}`}
                      </MenuItem>
                    ))}
                  </Select>

                  {touched.score && errors.score && (
                    <Typography color="error">{errors.score}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EpisodeTestScoreForm;
