import React, { useContext, useEffect } from "react";
import UserContext from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import useSecurity from "../../hooks/use-security";

const EpisodesSecurity = ({ children }) => {
  const { menuItems, securityGroupMenus } = useContext(UserContext);
  const menuItem = menuItems.find(
    (menuItem) => menuItem.menu_component === "PracticeEpisodesDataGrid"
  );
  const { canRead } = useSecurity({
    menuItems,
    pathname: menuItem?.menu_route,
    securityGroupMenus,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (menuItems.length <= 0) {
      return;
    }

    if (!canRead) {
      navigate("/");
    }
  }, [canRead, menuItems, navigate]);

  if (!canRead) return null;

  return <>{children}</>;
};

export default EpisodesSecurity;
