import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  InputLabel,
} from "@mui/material";

const validationSchema = Yup.object().shape({
  office_id: Yup.string().required("Location is required"),
  tms_coil_id: Yup.string().required("Coil is required"),
  tms_device_id: Yup.string().required("Device is required"),
  tms_protocol_id: Yup.string().required("Protocol is required"),
  status: Yup.string().required("Status is required"),
});

const statusArr = ["Active", "Inactive"];

const PracticeTMSTreatmentPlanForm = ({
  offices,
  practiceTmsCoils,
  protocols,
  selectedRow,
  onCancel,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{
        office_id: selectedRow?.office_id || "",
        tms_coil_id: selectedRow?.tms_coil_id || "",
        tms_device_id: selectedRow?.tms_device_id || "",
        tms_protocol_id: selectedRow?.tms_protocol_id || "",
        status: selectedRow?.status || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 600,
              mx: "auto",
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="location-label">Location</InputLabel>

                  <Select
                    label="Location"
                    labelId="location-label"
                    id="office"
                    name="office_id"
                    value={values.office_id}
                    onChange={(e) => {
                      setFieldValue("office_id", e.target.value);
                    }}
                    error={touched.office_id && Boolean(errors.office_id)}
                  >
                    {offices.map((office, index) => (
                      <MenuItem key={`${office.id}-${index}`} value={office.id}>
                        {`${office.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.office_id && errors.office_id && (
                    <Typography color="error">{errors.office_id}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="protocol-label">Protocol</InputLabel>

                  <Select
                    label="Protocol"
                    labelId="protocol-label"
                    id="protocol"
                    name="protocol_id"
                    value={values.tms_protocol_id || ""}
                    onChange={(e) => {
                      setFieldValue("tms_protocol_id", e.target.value);
                    }}
                    error={
                      touched.tms_protocol_id && Boolean(errors.tms_protocol_id)
                    }
                  >
                    {protocols.map((protocol) => (
                      <MenuItem
                        key={protocol.tms_protocol_id}
                        value={protocol.tms_protocol_id}
                      >
                        {`${protocol.tms_protocol_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.tms_protocol_id && errors.tms_protocol_id && (
                    <Typography color="error">
                      {errors.tms_protocol_id}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="coil-label">Coil</InputLabel>

                  <Select
                    label="Coil"
                    labelId="coil-label"
                    id="coil"
                    name="tms_coil_id"
                    value={values.tms_coil_id || ""}
                    onChange={(e) => {
                      setFieldValue("tms_coil_id", e.target.value);
                      const item = practiceTmsCoils.find(
                        (item) => item.coil_id === e.target.value
                      );

                      if (item) {
                        setFieldValue("tms_device_id", item.device_id);
                      }
                    }}
                    error={touched.coil_id && Boolean(errors.coil_id)}
                  >
                    {practiceTmsCoils.map((item) => (
                      <MenuItem key={item.id} value={item.coil_id}>
                        {`${item.device_name} | ${item.coil_model_number}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.coil_id && errors.coil_id && (
                    <Typography color="error">{errors.coil_id}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="office-label">Status</InputLabel>

                  <Select
                    label="Status"
                    labelId="status-label"
                    id="status"
                    name="status"
                    value={values.status}
                    onChange={(e) => setFieldValue("status", e.target.value)}
                    error={touched.status && Boolean(errors.status)}
                  >
                    {statusArr.map((stats) => (
                      <MenuItem key={stats} value={stats}>
                        {`${stats}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.status && errors.status && (
                    <Typography color="error">{errors.status}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PracticeTMSTreatmentPlanForm;
