import React from "react";
import TabStructure from "../../components/tabStructure/TabStructure";

const tabs = [
  { label: "By Practice", value: "practice_name" },
  { label: "By Office", value: "office_name" },
  { label: "By Practitioner", value: "practitioner_name" },
  { label: "By Coil", value: "coil_model" },
  { label: "By Baseline Severity", value: "baseline_indication" },
];

const fieldOptions = [
  {
    name: "age_category",
    label: "Age Category equals",
    options: [],
  },
  { name: "gender_birth", label: "Gender equals", options: [] },
  {
    name: "start_year",
    label: "Year equals",
    options: [],
  },
  {
    name: "baseline_indication",
    label: "Severity",
    options: [],
  },
  {
    name: "questionnaire_acronym",
    label: "Questionnaire",
    options: [],
  },
  {
    name: "office_name",
    label: "Office",
    options: [],
  },
  {
    name: "practitioner_name",
    label: "Practitioner",
    options: [],
  },
];

// Define fieldOptions for each tab
const fieldOptionsByTab = {
  practice_name: fieldOptions,
  office_name: fieldOptions,
  practitioner_name: fieldOptions,
  coil_model: fieldOptions,
  baseline_indication: fieldOptions,
};

const PatientRetentionAnalysis = () => {
  const title = "Follow Ups";

  return (
    <TabStructure
      tableName="episode_outcomes"
      tabs={tabs}
      fieldOptionsByTab={fieldOptionsByTab}
      countByKey="outcome"
      title={title}
    />
  );
};

export default PatientRetentionAnalysis;
