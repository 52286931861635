import { getOne } from '../utils/API';

export function validateIsInteger(key, value) {
  if (typeof value === 'number' && Number.isInteger(value)) {
    return true; // It's already an integer
  } else if (typeof value === 'string') {
    const num = parseInt(value, 10);
    if (!isNaN(num) && Number.isInteger(num)) {
      return true; // Successfully parsed as an integer
    }
  }

  // If it's not a valid integer, throw an error
  const customError = new Error('Data Validation Error');
  customError.message = `${key} must be an integer, ${value}`;
  throw customError;
}

export function validateRequiredAttributes(
  requiredAttributes,
  attributeNames,
  row
) {
  const missingAttributes = [];

  for (let i = 0; i < requiredAttributes.length; i++) {
    const key = requiredAttributes[i];
    const attributeName = attributeNames[i];

    if (
      !(key in row) ||
      !row[key] ||
      row[key] === null ||
      row[key] === undefined ||
      (typeof row[key] === 'string' && row[key].trim() === '')
    ) {
      missingAttributes.push(attributeName);
    }
  }

  if (missingAttributes.length) {
    const lastAttribute = missingAttributes.pop();
    const errorMessage = missingAttributes.length
      ? `${missingAttributes.join(
          ', '
        )} and ${lastAttribute} are required fields\n`
      : `${lastAttribute} is a required field\n`;

    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = errorMessage;
    throw customError;
  }
  return true;
}

export function validateDateDefined(value, field) {
  if (value instanceof Date) {
    if (isNaN(value.getTime())) {
      const customError = new Error();
      customError.name = 'Data Validation Error';
      customError.message = `${field} is a required field.`;
      throw customError;
    } else {
      return true;
    }
  } else {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = `${field} is a required field.`;
    throw customError;
  }
}

export function validateDateNotDefined(value, field) {
  if (value === undefined) {
    return true;
  }

  if (isNaN(value.getTime())) {
    return true;
  } else {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = `Treatment with a status of "In Progress", can't have an ${field}.  If treatment is complete, change the status to "Completed", "Discontinued" or "Terminated"`;
    throw customError;
  }
}

export function validateEmailFormat(email) {
  // A common regex pattern for basic email validation
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  if (!emailRegex.test(email)) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = `${email} is an invalid email address`;
    throw customError;
  }
  return true;
}

export function validatePostalCodeFormat(postalCode) {
  if (postalCode === '00000') {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = `Postal code ${postalCode} does not exist`;
    throw customError;
  }

  if (postalCode.length !== 5) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message =
      'Invalid format for a postal code, 5 digits are required';
    throw customError;
  }

  const regex = /^[0-9]{1,5}$/;
  if (!regex.test(postalCode)) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message =
      'Invalid format for a postal code, only numbers are allowed';
    throw customError;
  }

  return true;
}

export async function validatePostalCodeExists(postalCode) {
  try {
    const data = await getOne('postal_codes', { postal_code: postalCode });
    return data;
  } catch (error) {
    if (error.name === 'Data not found') {
      const customError = new Error();
      customError.name = 'Data Validation Error';
      customError.message = 'Zip Code not found';
      throw customError;
    } else {
      throw error;
    }
  }
}

export function validateDateString(dateString) {
  // Validate its a string
  if (typeof dateString !== 'string') {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message =
      'Invalid date format. Expected a string in YYYY-MM-DD format.';
    throw customError;
  }

  // Validate the format: YYYY-MM-DD
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regex)) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message =
      'Invalid date format. Expected a string in YYYY-MM-DD format.';
    throw customError;
  }

  // Ensure it's a valid date
  const parts = dateString.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  const dateObject = new Date(year, month - 1, day);
  if (
    dateObject.getUTCFullYear() !== year ||
    dateObject.getUTCMonth() + 1 !== month ||
    dateObject.getUTCDate() !== day
  ) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = 'Invalid date.';
    throw customError;
  }

  // Check if the date is in the future
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Reset the time of the current date to compare correctly
  if (dateObject > currentDate) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = 'The date cannot be in the future.';
    throw customError;
  }

  // Check if the date is more than 115 years ago
  const maxPastDate = new Date();
  maxPastDate.setFullYear(currentDate.getFullYear() - 115);
  if (dateObject < maxPastDate) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = 'The date cannot be more than 115 years ago.';
    throw customError;
  }

  return true;
}

export function validateBirthDate(dateObject) {
  // Validate its a date object
  if (!(dateObject instanceof Date)) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = 'Invalid date. Expected a Date object.';
    throw customError;
  }

  // Ensure it's a valid date
  if (isNaN(dateObject.getTime())) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = 'Invalid date.';
    throw customError;
  }

  // Check if the date is in the future
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Reset the time of the current date to compare correctly
  if (dateObject > currentDate) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = 'Invalid date, the date cannot be in the future.';
    throw customError;
  }

  // Check if the date is more than 115 years ago
  const maxPastDate = new Date();
  maxPastDate.setFullYear(currentDate.getFullYear() - 115);
  if (dateObject < maxPastDate) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = 'The date cannot be more than 115 years ago.';
    throw customError;
  }

  return true;
}

export function validateEndDateGreaterThanStartDate(startDate, endDate) {
  //'validateEndDateGreaterThanStartDate', startDate, endDate);
  // Convert dates to milliseconds since January 1, 1970, 00:00:00 UTC
  const startMilliseconds = startDate.getTime();
  const endMilliseconds = endDate.getTime();

  // Compare milliseconds to check if end date is greater than start date
  if (endMilliseconds > startMilliseconds) {
    return true; // End date is greater than start date
  } else {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message =
      'The end date can not be earlier than the start date.';
    throw customError;
  }
}

function formatDate(date) {
  const dateOject = typeOfDate(date, 'object');
  const day = dateOject.getDate().toString().padStart(2, '0');
  const month = (dateOject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const year = dateOject.getFullYear();
  return `${month}/${day}/${year}`;
}

export function validateDate2GreaterThanOrEqualDate1(
  date1,
  date1Name,
  date2,
  date2Name
) {
  // Convert dates to milliseconds since January 1, 1970, 00:00:00 UTC

  const dateObject1 = typeOfDate(date1, 'object');
  const dateObject2 = typeOfDate(date2, 'object');

  const startMilliseconds = dateObject1.getTime();
  const endMilliseconds = dateObject2.getTime();

  // Compare milliseconds to check if end date is greater than start date
  if (endMilliseconds >= startMilliseconds) {
    return true; // End date is greater than start date
  } else {
    const date1String = formatDate(date1);
    const date2String = formatDate(date2);
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = `${date2Name}, ${date2String} must occur at the same time or after ${date1Name}, ${date1String}`;
    throw customError;
  }
}

export function validateNotFutureDate(date, field) {
  // Get the current date
  const currentDate = new Date();

  // Compare the given date with the current date
  if (date.getTime() > currentDate.getTime()) {
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = `${field} can't be in the future.`;
    throw customError;
  } else {
    return true; // Date is not in the future
  }
}

// This returns a date in correct datatype, you don't need to know what the
// source data type is.
export function typeOfDate(date, dataTypeRequired) {
  //console.log('date:', date);
  //console.log('date datatype:', typeof date);
  if (dataTypeRequired === 'string') {
    if (typeof date !== 'string') {
      const dateString = date.toISOString().slice(0, 10);
      return dateString;
    } else {
      return date;
    }
  } else if (dataTypeRequired === 'object') {
    if (typeof date === 'string') {
      const dateString = date.replace(/-/g, '/');
      const dateObject = new Date(dateString);
      return dateObject;
    } else {
      return date;
    }
  }
}

export function validateUniqueEmail(persons, newPerson) {
  const emailToCheck = newPerson.email.toLowerCase(); // Convert to lowercase
  const personsWithSameEmail = persons.filter(
    (person) => person.email.toLowerCase() === emailToCheck // Convert to lowercase
  );

  for (const person of personsWithSameEmail) {
    if (person.id !== newPerson.id) {
      const first = person.first_name;
      const last = person.last_name;
      const fullName = first + ' ' + last;
      const customError = new Error();
      customError.name = 'Duplicate Email Error';
      customError.message = `This email ${newPerson.email}, is already being used by ${fullName}.`;
      throw customError;
    }
  }
}

export function validateUniqueProperty(objects, newObject, propertyName) {
  const propertyToCheck = newObject[propertyName].toLowerCase(); // Convert to lowercase
  const objectsWithSameProperty = objects.filter(
    (object) => object[propertyName].toLowerCase() === propertyToCheck // Convert to lowercase
  );

  for (const object of objectsWithSameProperty) {
    if (object.id !== newObject.id) {
      const customError = new Error();
      customError.name = `Duplicate ${propertyName} Error`;
      customError.message = `${
        newObject[propertyName]
      } is already being used by ${object.name || object.id}.`;
      throw customError;
    }
  }
}

export function validateUniqueProperties(objects, newObject, propertyNames) {
  // Check if all required parameters are provided
  if (
    !objects ||
    !Array.isArray(objects) ||
    !newObject ||
    !propertyNames ||
    !Array.isArray(propertyNames) ||
    propertyNames.length === 0
  ) {
    throw new Error('Invalid parameters provided.');
  }

  const propertiesToCheck = propertyNames.map((property) => ({
    name: property,
    value: newObject[property],
  }));

  const duplicateObject = objects.find(
    (object) =>
      propertiesToCheck.every(({ name, value }) => object[name] === value) &&
      object.id !== newObject.id // Exclude the current object from the comparison
  );

  if (duplicateObject) {
    const duplicatePropertyValues = propertiesToCheck.map(({ value }) => value);
    const customError = new Error();
    customError.name = 'Data Validation Error';
    customError.message = `${duplicatePropertyValues.join(
      ' with '
    )} already exists.`;
    throw customError;
  }

  return true;
}

export function validateUniquePatient(patients, newPatient) {
  const firstNameToCheck = newPatient.first_name.toLowerCase();
  const lastNameToCheck = newPatient.last_name.toLowerCase();
  const birthdateToCheck = typeOfDate(newPatient.birthdate, 'string');

  // Using find() to get the first match
  const duplicatePatient = patients.find(
    (patient) =>
      patient.first_name.toLowerCase() === firstNameToCheck &&
      patient.last_name.toLowerCase() === lastNameToCheck &&
      patient.birthdate === birthdateToCheck
  );

  if (duplicatePatient) {
    if (duplicatePatient.id === newPatient.id) {
      // It found itself in rows, this happens on edits/updates
      return;
    }
    const last = duplicatePatient.first_name;
    const first = duplicatePatient.last_name;
    const fullName = first + ' ' + last;
    const customError = new Error();
    customError.name = 'Duplicate Patient Error';
    customError.message = `Patient ${fullName} with birthdate ${birthdateToCheck} already exists.`;
    throw customError;
  }
}
