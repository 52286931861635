import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  InputLabel,
} from "@mui/material";

const status = ["Active", "Inactive"];

const PracticeTreatmentStatusForm = ({
  onCancel,
  onSubmit,
  treatmentTypes,
  selectedRow,
  treatmentStatus,
  rows,
}) => {
  const validationSchema = Yup.object().shape({
    treatment_status_id: Yup.string().required("Treatment Status is required"),
    treatment_type_id: Yup.string()
      .required("Treatment Type is required")
      .test(
        "is-score-in-range",
        "This combination is already exist",
        function (value) {
          const { treatment_status_id } = this.parent;
          const isExist = rows
            .filter((row) => row.id !== selectedRow?.id)
            .some(
              (row) =>
                row.treatment_status_id === Number(treatment_status_id) &&
                row.treatment_type_id === Number(value)
            );

          return !isExist;
        }
      ),
    status: Yup.string().required("Status is required"),
  });

  return (
    <Formik
      initialValues={{
        treatment_status_id: selectedRow?.treatment_status_id || "",
        treatment_type_id: selectedRow?.treatment_type_id || "",
        status: selectedRow?.status || "Active",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, touched, handleChange, setFieldValue, values }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 600,
              mx: "auto",
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="treatment-status-label">
                    Treatment Status
                  </InputLabel>

                  <Select
                    label="Treatment Status"
                    labelId="treatment-status-label"
                    id="treatment_status_id"
                    name="treatment_status_id"
                    value={values.treatment_status_id}
                    onChange={(e) => {
                      setFieldValue("treatment_status_id", e.target.value);
                    }}
                    error={
                      touched.treatment_status_id &&
                      Boolean(errors.treatment_status_id)
                    }
                  >
                    {treatmentStatus.map((tStatus) => (
                      <MenuItem
                        key={`treatment-type-${tStatus.id}`}
                        value={tStatus.id}
                      >
                        {`${tStatus.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.treatment_status_id &&
                    errors.treatment_status_id && (
                      <Typography
                        color="error"
                        variant="caption"
                        sx={{ mx: "14px" }}
                      >
                        {errors.treatment_status_id}
                      </Typography>
                    )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="treatment-type-label">
                    Treatment Type
                  </InputLabel>

                  <Select
                    label="Treatment Type"
                    labelId="treatment-type-label"
                    id="treatment_type_id"
                    name="treatment_type_id"
                    value={values.treatment_type_id}
                    onChange={(e) => {
                      setFieldValue("treatment_type_id", e.target.value);
                    }}
                    error={
                      touched.treatment_type_id &&
                      Boolean(errors.treatment_type_id)
                    }
                  >
                    {treatmentTypes.map((treatmentType) => (
                      <MenuItem
                        key={`treatment-type-${treatmentType.id}`}
                        value={treatmentType.id}
                      >
                        {`${treatmentType.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.treatment_type_id && errors.treatment_type_id && (
                    <Typography
                      color="error"
                      variant="caption"
                      sx={{ mx: "14px" }}
                    >
                      {errors.treatment_type_id}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>

                  <Select
                    label="Status"
                    labelId="status-label"
                    id="status"
                    name="status"
                    value={values.status}
                    onChange={(e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    error={touched.status && Boolean(errors.status)}
                  >
                    {status.map((status) => (
                      <MenuItem key={status} value={status}>
                        {`${status}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.status && errors.status && (
                    <Typography color="error">{errors.status}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PracticeTreatmentStatusForm;
