import React from 'react';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from 'chart.js';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../Header';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
  ChartDataLabels
);

const LineChart = ({
  labels,
  title,
  subtitle,
  displayTitle,
  displaySubtitle,
  xAxisLabel,
  yAxisLabel,
  maxYValue,
  showYAxisPercent,
  legendPosition,
  displayDataLabels,
  dataSets,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = theme.typography;

  const data = {
    labels: labels,
    datasets: dataSets,
  };

  const xAxisConfig = {
    beginAtZero: true,
    stacked: false,
    title: {
      display: true,
      text: xAxisLabel,
      font: {
        family: typography.h6.fontFamily || typography.fontFamily,
        size: parseFloat(typography.h6.fontSize),
        weight: typography.h6.fontWeight,
      },
    },
    ticks: {
      align: 'center',
    },
  };

  const yAxisConfig = {
    beginAtZero: true,
    stacked: false,
    title: {
      display: true,
      text: yAxisLabel,
      font: {
        family: typography.h6.fontFamily || typography.fontFamily,
        size: parseFloat(typography.h6.fontSize),
        weight: typography.h6.fontWeight,
      },
    },
    ticks: {
      align: 'center', // Align y-axis labels to the center
      callback: function (value) {
        if (showYAxisPercent) {
          return value !== 0 ? `${value}%` : '';
        } else {
          return value !== 0 ? value : '';
        }
      },
    },
  };

  if (maxYValue !== undefined) {
    yAxisConfig.max = maxYValue;
  } else if (showYAxisPercent) {
    yAxisConfig.max = 100;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.15,
    scales: {
      x: xAxisConfig,
      y: yAxisConfig,
    },
    plugins: {
      legend: {
        position: legendPosition,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 30, // Add padding between legend items
        },
        onClick: (e, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          const meta = ci.getDatasetMeta(index);

          meta.hidden = !meta.hidden;

          ci.update();
        },
      },
      title: {
        display: displayTitle,
        text: title,
        padding: displayTitle ? { top: 0, bottom: 20 } : 0,
        font: {
          family: typography.h2.fontFamily || typography.fontFamily,
          size: parseFloat(typography.h2.fontSize),
          weight: typography.h2.fontWeight,
        },
      },
      subtitle: {
        display: displaySubtitle,
        text: subtitle,
        padding: displaySubtitle ? { top: 10, bottom: 10 } : 0,
        font: {
          family: typography.h4.fontFamily || typography.fontFamily,
          size: parseFloat(typography.h4.fontSize),
          weight: typography.h4.fontWeight,
        },
      },
      tooltip: {
        caretPadding: 10,
      },
      datalabels: {
        display: displayDataLabels,
        color: colors.primary[100],
        align: 'end',
        anchor: 'end',
      },
    },
  };

  return (
    <Box>
      <Header title={title} subtitle={subtitle} />
      <Box
        sx={{
          width: '100%',
          maxWidth: '90%',
          ml: 'auto',
          mr: 'auto',
          height: '600px',
        }}
      >
        <Line data={data} options={options} />
      </Box>
    </Box>
  );
};

export default LineChart;
