// BarChart.js
import React from "react";
import { Bar } from "react-chartjs-2";
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
  BarElement,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
  ChartDataLabels
);

const BarChart = ({
  data,
  legendPosition = "top",
  xTitle = "X Axis",
  yTitle = "Y Axis",
  displayDataLabels = true,
  yTitleDisplay = false,
  subtitle = "",
  title = "",
  displayTitle = false,
  displaySubtitle = false,
  displayDigits,
  displayPercent,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = theme.typography;
  const isDark = theme.palette.mode === "dark";
  const gridColor = isDark ? colors.grey[800] : colors.grey[100];

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: xTitle,
          color: typography.color,
          font: {
            size: parseFloat(typography.h6.fontSize),
            weight: typography.h6.fontWeight,
          },
        },
        ticks: {
          color: typography.color,
        },
        grid: {
          color: gridColor,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: yTitleDisplay,
          text: yTitle,
          color: typography.color,
          font: {
            size: parseFloat(typography.h6.fontSize),
            weight: typography.h6.fontWeight,
          },
        },
        ticks: {
          color: typography.color,
          callback: function (value) {
            if (displayPercent) {
              return `${value}%`;
            }
            return displayDigits ? value.toFixed(displayDigits) : value;
          },
        },
        grid: {
          color: gridColor,
        },
      },
    },
    plugins: {
      title: {
        display: displayTitle,
        text: title,
        color: typography.color,
        padding: displaySubtitle ? { top: 0, bottom: 0 } : { bottom: 20 },
        font: {
          family: typography.h2.fontFamily || typography.fontFamily,
          size: parseFloat(typography.h2.fontSize),
          weight: typography.h2.fontWeight,
        },
      },
      subtitle: {
        display: displaySubtitle,
        text: subtitle,
        color: typography.color,
        padding: displaySubtitle ? { top: 0, bottom: 20 } : 0,
        font: {
          family: typography.h4.fontFamily || typography.fontFamily,
          size: parseFloat(typography.h4.fontSize),
          weight: typography.h4.fontWeight,
        },
      },
      legend: {
        position: legendPosition,
        labels: {
          color: typography.color,
        },
      },
      tooltip: {
        caretPadding: 10,
      },
      datalabels: {
        display: displayDataLabels,
        color: typography.color,
        font: {
          family: typography.h6.fontFamily || typography.fontFamily,
          size: 12,
          weight: 700,
        },
        align: "end",
        anchor: "end",
        formatter: (value) => {
          return displayPercent ? `${value}%` : value;
        },
      },
    },
  };

  return (
    <Box maxHeight={`600px`} width={`100%`}>
      <Bar data={data} options={options} height={`100%`} />
    </Box>
  );
};

export default BarChart;
