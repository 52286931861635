import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  InputLabel,
} from "@mui/material";

const status = ["Active", "Inactive"];

const MenuItemForm = ({
  onCancel,
  onSubmit,
  menuComponents,
  menus,
  selectedRow,
  rows,
}) => {
  const videoComponent = menuComponents.find(
    (component) => component.name === "VideoPlayer"
  );
  const videoComponentId = videoComponent.id;

  const validationSchema = Yup.object().shape({
    menu_id: Yup.string().required("Menu name is required"),
    menu_section: Yup.string(),
    menu_item: Yup.string()
      .required("Menu Item Name is required")
      .test(
        "unique-combo1",
        "This combination already exists.",
        function (value) {
          const { menu_id } = this.parent;
          const menu = menus.find((item) => item.id === Number(menu_id));
          const duplicatedRow = rows
            .filter((row) => row.menu_id === Number(menu_id))
            .filter((row) => row.menu_item !== selectedRow?.menu_item)
            .find((obj) => obj.menu_item === value);

          if (Boolean(duplicatedRow)) {
            return this.createError({
              path: this.path,
              message: `This ${menu.name} and ${duplicatedRow.menu_item} already exists`,
            });
          }

          return true;
        }
      ),
    menu_route: Yup.string()
      .matches(
        /^\/[a-zA-Z0-9-/]*$/,
        "Menu Route must start with a slash and contain only letters, numbers, hyphens, and slashes"
      )
      .required("Menu Route is required")
      .test("unique-combo2", "This route already exists.", function (value) {
        const isUnique = rows
          .filter((row) => row.menu_route !== selectedRow?.menu_route)
          .every((obj) => obj.menu_route !== value);
        return isUnique;
      }),
    menu_component_id: Yup.string().required("Menu Component is required"),
    video_link: Yup.string().when("menu_component_id", {
      is: `${videoComponentId}`,
      then: Yup.string()
        .required("Video Link is required")
        .url("Video Link must be a valid URL"), // Validates that the link is a proper URL
      otherwise: Yup.string().url("Video Link must be a valid URL"),
    }),
    status: Yup.string().required("Status is required"),
  });

  return (
    <Formik
      initialValues={{
        menu_id: selectedRow?.menu_id || "",
        menu_section: selectedRow?.menu_section || "",
        menu_item: selectedRow?.menu_item || "",
        menu_route: selectedRow?.menu_route || "",
        description: selectedRow?.description || "",
        menu_component_id: selectedRow?.menu_component_id || "",
        status: selectedRow?.status || "Active",
        video_link: selectedRow?.video_link || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, touched, handleChange, setFieldValue, values }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 600,
              mx: "auto",
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="menu-label">Menu Name</InputLabel>

                  <Select
                    label="Menu Name"
                    labelId="menu-label"
                    id="menu_id"
                    name="menu_id"
                    value={values.menu_id}
                    onChange={(e) => {
                      setFieldValue("menu_id", e.target.value);
                    }}
                    error={touched.menu_id && Boolean(errors.menu_id)}
                  >
                    {menus.map((menu) => (
                      <MenuItem key={menu.id} value={menu.id}>
                        {`${menu.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.menu_id && errors.menu_id && (
                    <Typography
                      color="error"
                      variant="caption"
                      sx={{ mx: "14px" }}
                    >
                      {errors.menu_id}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="menu_section"
                  as={TextField}
                  label="Menu Section"
                  type="text"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={values.menu_section}
                  onChange={handleChange}
                  error={touched.menu_section && Boolean(errors.menu_section)}
                  helperText={touched.menu_section && errors.menu_section}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="menu_item"
                  as={TextField}
                  label="Menu Item Name"
                  type="text"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={values.menu_item}
                  onChange={handleChange}
                  error={touched.menu_item && Boolean(errors.menu_item)}
                  helperText={touched.menu_item && errors.menu_item}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="menu-component-label">
                    Menu Component
                  </InputLabel>

                  <Select
                    label="Menu Component"
                    labelId="menu-component-label"
                    id="menu_component_id"
                    name="menu_component_id"
                    value={values.menu_component_id}
                    onChange={(e) => {
                      setFieldValue("menu_component_id", e.target.value);
                    }}
                    error={
                      touched.menu_component_id &&
                      Boolean(errors.menu_component_id)
                    }
                  >
                    {menuComponents.map((component) => (
                      <MenuItem
                        key={component.id}
                        value={component.id}
                        // disabled={rows.find(
                        //   (row) => row.menu_component_id === component.id
                        // )}
                      >
                        {`${component.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.menu_component_id && errors.menu_component_id && (
                    <Typography
                      color="error"
                      variant="caption"
                      sx={{ mx: "14px" }}
                    >
                      {errors.menu_component_id}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              {Number(values.menu_component_id) === videoComponentId && (
                <Grid item xs={12}>
                  <Field
                    name="video_link"
                    as={TextField}
                    label="Vide Link"
                    type="text"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.video_link}
                    onChange={handleChange}
                    error={touched.video_link && Boolean(errors.video_link)}
                    helperText={touched.video_link && errors.video_link}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Field
                  name="menu_route"
                  as={TextField}
                  label="Menu Route"
                  type="text"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={values.menu_route}
                  onChange={handleChange}
                  error={touched.menu_route && Boolean(errors.menu_route)}
                  helperText={touched.menu_route && errors.menu_route}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="description"
                  as={TextField}
                  label="Description"
                  type="text"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={values.description}
                  onChange={handleChange}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>

                  <Select
                    label="Status"
                    labelId="status-label"
                    id="status"
                    name="status"
                    value={values.status}
                    onChange={(e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    error={touched.status && Boolean(errors.status)}
                  >
                    {status.map((status) => (
                      <MenuItem key={status} value={status}>
                        {`${status}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.status && errors.status && (
                    <Typography color="error">{errors.status}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default MenuItemForm;
