import * as React from "react";
import "../../../index.css";
import { useEffect, useState, useContext } from "react";
import DataEntry from "../../../components/datagrid/dataEntry";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData, deleteData } from "../../../utils/API";
import { validateRequiredAttributes } from "../../../utils/ValidationUtils";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { useLocation } from "react-router-dom";
import useSecurity from "../../../hooks/use-security";

// *************** CUSTOMIZE ************** START
export default function ProcedureCodesGrid() {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const title = "Procedure Codes";
  let subtitle = `View ${title}`;

  const { pathname } = useLocation();
  const { menuItems, securityGroupMenus } = useContext(UserContext);
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });

  const sort_1 = "procedure_category_name";
  const sort_2 = "code";
  const table = "procedure_codes";
  const relatedTable = "procedure_categories";
  const requiredAttributes = [
    "code",
    "procedure_category_id",
    "source",
    "name",
    "description",
    "status",
  ];
  const attributeNames = [
    "Procedure Code",
    "Procedure Category",
    "Source",
    "Name",
    "Description",
    "Status",
  ];

  function createRowData(rows) {
    // IS THIS REDUNDANT, ITS ALSO IN DefaultToolBar
    const newId = Math.floor(100000 + Math.random() * 900000);
    return {
      id: newId,
      code: "",
      procedure_category_id: "",
      source: "American Medical Association",
      name: "",
      description: "",
      status: "Active",
    };
  }
  // *************** CUSTOMIZE ************** END

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);
  const [relatedData, setRelatedData] = useState([]);
  const [relatedObjects, setRelatedObjects] = useState([]);

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  useEffect(() => {
    setLoading(true);
    getData(table)
      .then((data) => {
        const sortedItems = sortItems(data, sort_1, sort_2);
        setRows(sortedItems);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleErrorNotification, relatedData]);

  useEffect(() => {
    setLoading(true);
    getData(relatedTable)
      .then((data) => {
        const relatedData = data.map((obj) => obj.name).sort();
        setRelatedData(relatedData);
        // Used to get the id property of user select a different category
        setRelatedObjects(data);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleErrorNotification]);

  // These need to load after the related data useEffect
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "code",
      headerName: "Procedure Code",
      editable: true,
      cellClassName: "name-column--cell",
    },
    {
      field: "procedure_category_name",
      headerName: "Procedure Name",
      type: "singleSelect",
      valueOptions: relatedData,
      editable: true,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      editable: true,
      cellClassName: "wrapWord",
      flex: 1,
    },
    {
      field: "source",
      headerName: "Source",
      editable: true,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: ["American Medical Association", "Custom"],
      defaultValueGetter: () => "Active",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: ["Active", "Inactive"],
      defaultValueGetter: () => "Active",
      flex: 1,
    },
  ];

  function sortItems(items, sort_attribute_1, sort_attribute_2) {
    return items.sort((a, b) => {
      // Primary criterion: sort_attribute_1
      const comparison_1 = a[sort_attribute_1].localeCompare(
        b[sort_attribute_1]
      );

      // If the primary criteria are the same and sort_attribute_2 is provided, sort by sort_attribute_2
      if (comparison_1 === 0 && sort_attribute_2) {
        return a[sort_attribute_2].localeCompare(b[sort_attribute_2]); // Secondary criterion
      }

      return comparison_1;
    });
  }

  async function validateRow(newRow, oldRow) {
    try {
      validateRequiredAttributes(
        ["procedure_category_name"],
        ["Procedure Category"],
        newRow
      );

      if (
        !oldRow ||
        newRow.procedure_category_name !== oldRow.procedure_category_name
      ) {
        const correspondingObject = relatedObjects.find(
          (obj) => obj.name === newRow.procedure_category_name
        );
        newRow.procedure_category_id = correspondingObject.id;
      }

      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      return newRow;
    } catch (error) {
      throw error;
    }
  }

  async function saveRow(id, row, oldRow, oldRows) {
    try {
      if (row.isNew) {
        const rowToSave = { ...row };
        delete rowToSave.id;
        rowToSave["active"] = "Active";
        const data = await postData(table, rowToSave);
        rowToSave.id = data.data.id;
        setRows(oldRows.map((r) => (r.id === id ? { ...rowToSave } : r)));
        return rowToSave;
      } else {
        await putData(table, row);
        setRows(oldRows.map((r) => (r.id === id ? { ...row } : r)));
        return row;
      }
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  async function deleteRow(id, row, oldRows) {
    const body = {
      id: row.id,
    };

    try {
      await deleteData(table, body);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntry
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        uniqueKey="ProcedureCodes"
        onValidateRow={validateRow}
        onSaveRow={saveRow}
        onDeleteRow={deleteRow}
        createRowData={createRowData}
        loading={loading}
        hideAddIcon={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />
    </div>
  );
}
